<template>
  <div class="resource-pager">
    <div class="classify flex jc-between ai-center">
      <div v-for="(item, index) in types" :key="index" :class="['item cursor-pointer', { actived: actived === index}]" @click="handleTypeClick(index)">{{ item.name }}</div>
    </div>
    <div class="table flex flex-wrap">
      <div v-for="(item, index) in list" :key="index" class="item flex flex-column relative cursor-pointer">
        <img :src="item.type" alt="" class="img absolute">
        <div class="txt absolute">{{ item.name }}</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        align="center"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 40, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      actived: 0,
      types: [
        {
          id: 0,
          name: '全部资料'
        },
        {
          id: 2,
          name: '文本文档'
        },
        {
          id: 3,
          name: '视频资料'
        },
        {
          id: 4,
          name: '全图资料'
        },
        {
          id: 5,
          name: '模型资料'
        }
      ],
      page: 1,
      size: 10,
      total: 10,
      list: [
        {
          type: require('../images/img-icon.png'),
          name: 'wechatimg577.jpg'
        },
        {
          type: require('../images/img-icon.png'),
          name: 'wechatimg578.jpg'
        },
        {
          type: require('../images/pdf-icon.png'),
          name: 'wechatimg579.jpg'
        },
        {
          type: require('../images/pdf-icon.png'),
          name: 'wechatimg590.jpg'
        },
        {
          type: require('../images/word-icon.png'),
          name: 'wechatimg597.jpg'
        },
        {
          type: require('../images/video-icon.png'),
          name: 'wechatimg567.jpg'
        },
        {
          type: require('../images/video-icon.png'),
          name: 'wechatimg566.jpg'
        },
        {
          type: require('../images/video-icon.png'),
          name: 'wechatimg565.jpg'
        },
        {
          type: require('../images/video-icon.png'),
          name: 'wechatimg564.jpg'
        },
        {
          type: require('../images/video-icon.png'),
          name: 'wechatimg563.jpg'
        },
      ]
    };
  },
  created() {
    this.getData()
  },
  activated() {
  },
  methods: {
    handleTypeClick(index) {
      this.actived = index
    },
    getData() {
      // queryStudyDetail({
      //   page: this.data.page,
      //   size: this.data.size
      // }).then(res => {
      //   if (res.code === 200) {
      //     this.list = res.data.rows || [];
      //     this.total = res.data.total
      //   }
      // });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  }
};
</script>
<style lang="scss">
.resource-pager {
  padding-top: 39px;
  .classify {
    width: 1400px;
    margin: 0 auto;
    .item {
      box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
      border-radius: 10px;
      font-weight: bold;
      font-size: 20px;
      line-height: 76px;
      margin: 0 50px;
      padding: 0 38px;
      background: #FFFFFF;
      color: #666666;
      &.actived {
        background: #327CED;
        color: #FFFFFF;
      }
    }
  }
  .table {
    width: 1400px;
    margin: 20px auto;
    .item {
      width: 280px;
      height: 264px;
      .img {
        left: 0;
        top: 0;
        width: 280px;
        height: 264px;
      }
      .txt {
        left: 0;
        bottom: 20px;
        z-index: 2;
        width: 280px;
        font-weight: bold;
        font-size: 18px;
        color: #234984;
        line-height: 25px;
        text-align: center;
      }
    }
  }
  .pagination {
    margin: 30px 0;
  }
}
</style>
