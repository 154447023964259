<template>
  <div class="postComments">
    <BannerContent :id="this.$route.query.id" type="课程" />
    <div class="comments">
      <div class="title-wrapper flex">
        <div class="name">发表评价</div>
      </div>
      <div class="flex content">
        <div class="comments-content common">
          <div class="comments-title">评价内容</div>
          <div class="common-text">
            <el-input type="textarea" :rows="6" placeholder="请输入评价内容" v-model="form.evaluate">
            </el-input>
          </div>
        </div>
        <div class="comments-rate common">
          <div class="comments-title">进行评分</div>
          <div class="common-text">
            <div class="flex rate-wrapper">
              <span class="name">课 程 内 容</span>
              <el-rate v-model="form.content" :colors="['#3989EB', '#3989EB', '#3989EB']"></el-rate>
            </div>
            <div class="flex rate-wrapper">
              <span class="name">课 程 特 色</span>
              <el-rate v-model="form.feature" :colors="['#3989EB', '#3989EB', '#3989EB']"></el-rate>
            </div>
            <div class="flex rate-wrapper">
              <span class="name">课程通过率</span>
              <el-rate v-model="form.passingRate" :colors="['#3989EB', '#3989EB', '#3989EB']"></el-rate>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrapper flex jus-c">
        <div class="submitBtn"  @click="handleSubmit">提 交</div>
        <!-- <div class="cancelBtn">取 消</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { inStudyEvaluate, myStudyEvaluate } from "@/api/ApiConfig";
import BannerContent from "@/views/Experiment/components/bannerContent";
export default {
  components: { BannerContent },
  data() {
    return {
      evalData:{},
      form:{
        content: "",//课程内容分
        evaluate: "", //评价内容
        feature: "", //课程特色分
        passingRate: "", //课程通过率
      }
    };
  },
  mounted() {
    this.getmyStudyEvaluate()
  },
  methods: {
    getmyStudyEvaluate(){
      myStudyEvaluate({
        id: this.$route.query.id,
      }).then(res => {
        if (res.code === 200) {
          this.evalData = res.data
        }
      });
    },
    handleSubmit(){
      inStudyEvaluate({
        id: this.$route.query.id,
        ...this.form,
      }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.$router.go(-1)
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-rate__icon {
  font-size: 22px;
  margin-right: 30px;
}
/deep/ .el-textarea {
  textarea {
    resize: none;
  }
}
.postComments {
  padding-top: 100px;
  .comments {
    margin: 40px 0;
    background: #ffffff;
    padding: 29px 40px;
    box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
    border-radius: 8px;
    .title-wrapper {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 32px;
      .name {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        border-bottom: 4px solid #3989eb;
        padding-bottom: 20px;
      }
    }
    .content {
      .comments-title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        position: relative;
        padding-left: 17px;
        margin-bottom: 40px;
      }
      .comments-title:before {
        content: "";
        height: 14px;
        display: block;
        border-left: 3px solid #333e55;
        position: absolute;
        left: 0;
        top: 6px;
      }
      .common {
        width: 50%;
        padding: 0 80px 60px 60px;
        .common-text {
          padding-left: 17px;
        }
      }
      .rate-wrapper {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
        margin-top: 33px;
        .name {
          margin-right: 40px;
        }
      }
    }
    .btn-wrapper {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 31px;
      text-align: center;
      line-height: 46px;
      .submitBtn {
        width: 180px;
        height: 46px;
        background: #3989eb;
        border-radius: 4px;
        color: #ffffff;
        margin-right: 40px;
        cursor: pointer;
      }
      .cancelBtn {
        width: 180px;
        height: 46px;
        background: #e5e5e5;
        border-radius: 4px;
        color: #666666;
        cursor: pointer;
      }
    }
  }
}
</style>
