<template>
  <div class="chapter-box">
    <div class="title">目录</div>
    <ul class="list-group">
      <li class="list-li" v-for="(course, index) in data.chapterList" :key="index">
        <div class="course_box flex flex-column align-b" @click="toggleList(index)">
          <div class="ctitle"><i class="el-icon-coin" size="30"></i>{{ course.chapter }}</div>
          <div class="startBtn">开始学习</div>
        </div>
        <ul class="list" v-show="course.isExpanded">
          <li class="ctitle child" v-for="(item, index1) in course.children" :key="index1">
            <span
              @click="handleLearn(course, item)"
              :style="{
                color: data.videoSrc === item.videoLink ? '#3989EB' : ''
              }"
              ><i class="el-icon-video-play" size="30"></i>{{ item.jointName }}</span
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toggleList(index) {
      this.$set(
        this.data.chapterList[index],
        "isExpanded",
        !this.data.chapterList[index].isExpanded
      );
    },
    handleLearn(course, item) {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        this.$emit("handleLearn", course, item);
      }
    }
  }
};
</script>
<style lang="scss">
.chapter-box {
  width: 420px;
  height: 505px;
  background: #15181e;
  border-radius: 6px;
  position: relative;
  .title {
    height: 70px;
    line-height: 70px;
    padding-left: 10px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 2px;
    background: transparent;
  }
  .list-group {
    color: #fff;
    height: 435px;
    overflow-y: scroll;
    .list-li {
      border-bottom: 1px solid #15181e;
      cursor: pointer;
    }
    .course_box {
      width: 416px;
      height: 80px;
      background: #27282b;
      border-radius: 4px 4px 0px 0px;
      padding: 13px;
      box-sizing: border-box;
    }
    .ctitle {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      // color: #3989eb;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fff;
      i {
        margin-right: 8px;
      }
    }
    .child {
      padding: 10px 33px 10px;
    }
    .startBtn {
      cursor: pointer;
      margin-top: 10px;
      font-size: 14px;
      width: 70px;
      text-align: center;
      height: 24px;
      line-height: 24px;
      border: 1px solid #fff;
      border-radius: 12px;
      // color: #3989eb;
      color: #fff;
      margin-left: 20px;
    }
  }
}
</style>
