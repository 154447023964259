<template>
  <div class="footer flex flex-column jc-center ai-center">
    <div class="tel flex jc-centr ai-center">
      <img class="icon" src="../../images/tel.png" />
      <div class="txt">服务热线: {{ serviceHotline }}</div>
    </div>
    <div class="copyright">{{ copyright }}</div>
  </div>
</template>
<script>
import { queryStudyDetail } from "@/api/ApiConfig.js";
export default {
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      serviceHotline: '---',
      copyright: 'Copyright © ---'
    };
  },
  mounted() {
    this.queryStudyDetail()
  },
  methods: {
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.serviceHotline = res.data.serviceHotline
        this.copyright = res.data.copyright
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  height: 110px;
  background: #253350;
  .tel {
    .icon {
      width: 14px;
      height: 13px;
    }
    .txt {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      margin-left: 10px;
    }
  }
  .copyright {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 10px;
  }
}
</style>
  