<!-- 竖版 上面图片下边一行文字 -->
<template>
  <div class="PortraitOne">
    <article>
      <SearchSelect v-if="!hideHeader" :from_type="type" @change="handleChangeSearch"></SearchSelect>
      <div v-else class="title-wrapper flex">
        <div class="name">课程</div>
        <div class="num" v-if="isShowCollect">收藏共{{ pageTotal }}条</div>
      </div>
      <div class="recommend_list" v-if="list.length > 0">
        <div class="item" v-for="(item, index) in list" :key="index" @click="handleClick(item)">
          <div>
            <img class="left_img" :src="item.pic" alt="" />
          </div>

          <div class="left">
            <div class="xiaotitle">{{ item.name }}</div>
            <div class="content" v-html="item.filterContent.slice(0,32)+'...'"></div>
            <div class="shoucang flex">
              <p>
                <el-rate
                  :disabled="true"
                  v-model="item.divide"
                  text-color="#3989EB" 
                  :colors="['#3989EB', '#3989EB', '#3989EB']" 
                  disabled-void-color="#A8CFFF"
                ></el-rate>
              </p>
              <p>
                <span>{{ item.viewNum }}人浏览过</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex jus-c" style="padding: 220px 0;color: #000000;" v-else>暂无数据</div>
      <div class="pagination">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 40, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </article>
  </div>
</template>
<script>
import SearchSelect from "@/components/SearchSelect";
import { collectItem } from "@/api/ApiConfig.js";
export default {
  components: {
    SearchSelect
  },
  props: {
    pageTotal: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ""
    },
    listData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isShowCollect: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    listData: {
      handler(newValue, oldValue) {
        this.list = newValue.map(item => {
          item.divide = parseFloat(item.divide)
          return item
        });
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      personIcon: require("@/assets/images/login/person.png"),
      shou_icon: require("@/assets/images/home/icons/shou_icon.png"),
      eye_icon: require("@/assets/images/home/icons/eye_icon.png"),
      pageIndex: 1,
      pageSize: 10,
      list: []
    };
  },
  methods: {
    handleChangeSearch(selc, selb) {
      this.$emit('change', selc, selb)
    },
    // 分页导航
    handleSizeChange(val) {
      this.pageSize = val;
      this.$emit("getPageSize", val);
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.$emit("getPageIndex", val);
    },
    handleShou(item, channel) {
      collectItem({
        channel,
        id: item.id
      }).then(res => {
        if (res.code === 200) {
          this.handleCurrentChange(this.pageIndex);
        }
      });
    },
    handleClick(item) {
      if (this.type === "course") {
        this.$router.push({
          path: "/course/Detail",
          query: {
            id: item.id
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.PortraitOne {
  flex: 1;
  ul {
    list-style-type: none;
  }
  article {
    padding: 0 40px 40px 0;
    margin-bottom: 40px;
    border-radius: 8px;
    .title-wrapper {
      border-bottom: 1px solid #e5e5e5;
      .name {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        border-bottom: 4px solid #3989eb;
        padding-bottom: 20px;
        margin-right: 18px;
      }
      .num {
        font-size: 16px;
        font-weight: 400;
        color: #3989eb;
        line-height: 40px;
      }
    }

    .recommend_list {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: calc(33.33% - 24px);
        min-width: 270px;
        height: 290px;
        margin-bottom: 20px;
        box-shadow: 1px 1px 10px 1px #66666645;
        border-radius: 8px;
        margin-right: 12px;
        margin-left: 12px;
        cursor: pointer;
        .left_img {
          width: 100%;
          height: 150px;
          border-radius: 8px;
        }
        .left {
          width: 100%;
          border-radius: 8px;
          padding: 15px;
          box-sizing: border-box;
          .xiaotitle {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-align: center;
            font-weight: bold;
          }
          .content {
            height: 42px;
            padding: 0 5px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: 13px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .shoucang {
            justify-content: space-between;
            margin-top: 12px;

            p:nth-child(2) {
              margin-left: 40px;
            }
            img {
              width: 15px;
              height: 15px;
              margin-top: 5px;
              margin-right: 5px;
              cursor: pointer;
            }
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }
          .down {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #666;
            p {
              margin-top: 10px;
              .value {
                font-weight: bold;
              }
            }
          }
          .name-time-wrapper {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
