<template>
  <div class="navigate">
    <div class="items flex flex-wrap ai-center">
      <div v-for="(item, index) in list" :key="index" class="flex-1" @mouseover="handleMouseover(index)" @mouseleave="handleMouseleave()">
        <el-popover v-if="item.children && item.children.length > 0" placement="right" trigger="hover" popper-class="nav">
          <div slot="reference" :class="['item flex jc-center ai-center cursor-pointer', { 'actived': actived === index || selected(item) }]">
            <img class="icon" :src="actived === index || selected(item) ? item.icon_actived : item.icon" />
            <div class="txt">{{ item.name }}</div>
          </div>
          <div class="children">
            <div v-for="(child, idx) in item.children" :key="idx" :class="['child cursor-pointer', { 'actived': selectedChild(child) }]" @click="handleGoPager(child)">
              <div class="txt">{{ child.name }}</div>
            </div>
          </div>
        </el-popover>
        <div v-else :class="['item flex jc-center ai-center cursor-pointer', { 'actived': actived === index || selected(item) }]" @click="handleGoPager(item)">
          <img class="icon" :src="actived === index || selected(item) ? item.icon_actived : item.icon" />
          <div class="txt">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: ['pager', 'params'],
  data() {
    return {
      actived: null,
      list: [
        {
          icon: require('../../images/quick-icon1.png'),
          icon_actived: require('../../images/quick-actved-icon1.png'),
          name: '实验介绍',
          children: [
            {
              name: '实验教学目标',
              pager: 'pager1Detail',
              params: {
                type: 'teachingTarget',
                name: '实验教学目标'
              }
            },
            {
              name: '实验原理',
              pager: 'pager1Detail',
              params: {
                type: 'studyPrinciple',
                name: '实验原理'
              }
            },
            {
              name: '实验教学过程与实验方法',
              pager: 'pager1Detail',
              params: {
                type: 'processMethod',
                name: '实验教学过程与实验方法'
              }
            },
            {
              name: '实验步骤',
              pager: 'pager1Detail',
              params: {
                type: 'studyStep',
                name: '实验步骤'
              }
            },
            {
              name: '实验结果与结论',
              pager: 'pager1Detail',
              params: {
                type: 'conclusion',
                name: '实验结果与结论'
              }
            }
          ]
        },
        {
          icon: require('../../images/quick-icon2.png'),
          icon_actived: require('../../images/quick-actved-icon2.png'),
          name: '实验必读',
          pager: 'pager1Read',
          params: {}
        },
        {
          icon: require('../../images/quick-icon3.png'),
          icon_actived: require('../../images/quick-actved-icon3.png'),
          name: '数据统计',
          pager: 'pager1Chart',
          params: {}
        },
        {
          icon: require('../../images/quick-icon4.png'),
          icon_actived: require('../../images/quick-actved-icon4.png'),
          name: '实验文件',
          pager: 'pager1Files',
          params: {}
        },
        {
          icon: require('../../images/quick-icon5.png'),
          icon_actived: require('../../images/quick-actved-icon5.png'),
          name: '参与实验记录',
          pager: 'pager1Record',
          params: {}
        },
        {
          icon: require('../../images/quick-icon6.png'),
          icon_actived: require('../../images/quick-actved-icon6.png'),
          name: '教学团队',
          pager: 'pager1Team',
          params: {}
        },
        {
          icon: require('../../images/quick-icon7.png'),
          icon_actived: require('../../images/quick-actved-icon7.png'),
          name: '实验报告',
          pager: 'pager1Report',
          params: {}
        },
        {
          icon: require('../../images/quick-icon8.png'),
          icon_actived: require('../../images/quick-actved-icon8.png'),
          name: '考核要求',
          pager: 'pager1Detail',
          params: {
            type: 'ask',
            name: '考核要求'
          }
        },
        {
          icon: require('../../images/quick-icon9.png'),
          icon_actived: require('../../images/quick-actved-icon9.png'),
          name: '实验评分',
          pager: 'pager1Score',
          params: {}
        },
        {
          icon: require('../../images/quick-icon10.png'),
          icon_actived: require('../../images/quick-actved-icon10.png'),
          name: '实验教学项目的特色',
          pager: 'pager1Detail',
          params: {
            type: 'characteristic',
            name: '实验教学项目的特色'
          }
        }
      ]
    };
  },
  methods: {
    selected(item) {
      return (this.pager === item.pager && this.params.type === item.params.type) || (item.children && item.children.length > 0 &&item.children.some(it => this.pager === it.pager && this.params.type === it.params.type))
    },
    selectedChild(item) {
      return this.pager === item.pager && this.params.type === item.params.type
    },
    handleMouseover(index) {
      this.actived = index
    }, 
    handleMouseleave() {
      this.actived = null
    },
    handleGoPager(item) {
      if (item.pager) {
        this.$emit('change', {
          pager: item.pager,
          params: item.params
        })
      }
    }
  }
};
</script>
<style lang="scss">
.navigate {
  margin: 25px 0;
  .items {
    width: 1240px;
    margin: 0 auto;
    .item {
      min-width: 168px;
      height: 90px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
      border-radius: 10px;
      margin: 20px;
      padding: 0 20px;
      .icon {
        width: 38px;
        height: 38px;
      }
      .txt {
        line-height: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #666666;
        margin-left: 14px;
      }
      &.actived, &:hover {
        background: #327CED;
        .txt {
          color: #ffffff;
        }
      }
    }
  }
}

.nav .children {
  padding: 0 32px;
  background: #fff;
  .child {
    .txt {
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      color: #777777;
      border-bottom: solid 1px rgba(35, 73, 132, 0.1);
    }
    &:nth-last-child(1) .txt {
      border-bottom: none;
    }
    &.actived .txt, &:hover .txt {
      color: #327CED;
    }
  }
}
</style>
  