import axios from "axios";

// 登录请求方法
const loginreq = (method, url, params) => {
  return axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      token: localStorage.getItem("pcToken") || ""
    },
    data: params,
    traditional: true,
    transformRequest: [
      function(data) {
        let ret = "";
        for (let it in data) {
          ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ]
  }).then(res => res.data);
};
// 通用公用方法

const req = (method, url, params = {}, responseType = "", type = "") => {
  let param = {};
  if (method == "post") {
    // if(type =='query'){
    //     param = {
    //         params,
    //     }
    // }
    // param = {
    //     data: params,
    // }
    param = {
      // data: params,
      data: {
        ...params,
        token: localStorage.getItem("pcToken") || ""
      }
    };
  } else {
    //get请求
    param = {
      params
      // ...params,
      // token: localStorage.getItem("pcToken") || ""
    };
    param.token = localStorage.getItem("pcToken") || "";
  }

  return axios({
    responseType,
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem("pcToken") || ""
    },
    ...param,
    traditional: true
  }).then(res => res.data);
};

export { loginreq, req };

// transformRequest: [
//     function(data) {
//         let ret = ''
//         for (let it in data) {
//             ret +=
//                 encodeURIComponent(it) +
//                 '=' +
//                 encodeURIComponent(data[it]) +
//                 '&'
//         }
//         return ret
//     }
// ]
