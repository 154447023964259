<template>
  <div class="notifyMessage flex box_1200">
    <PersonMenu />
    <div class="Message">
      <article>
        <div class="title-wrapper flex jus-b align-c">
          <div class="name">通知</div>
          <div class="input flex align-c">
            <el-input v-model="inputValue" placeholder="请输入搜索关键词" clearable></el-input>
            <div class="search-wrapper" @click="getData">
              <img :src="searchIcon" alt="" />
            </div>
          </div>
        </div>
        <ul class="menu-news flex">
          <li
            class="main_tab"
            v-for="item in tabNotice"
            :key="item.value"
            :label="item.value"
            @click="handleClick(item)"
            :class="number == item.value ? 'blue' : ''"
          >
            <span>{{ item.label }}</span>
          </li>
        </ul>
        <ul v-if="listData.length > 0">
          <li class="flex" v-for="(item, index) in listData" :key="index" @click="pathDetail(item)">
            <img :src="item.pic" alt="" class="banner" />
            <div class="article-content">
              <div class="article-title one-word-ellipsis">{{ item.title }}</div>
              <div class="articles four-word-ellipsis word-break">
                {{ ToText(item.filterContent) }}
              </div>
              <div class="flex jus-b align-c">
                <div class="read-wrapper flex">
                  <div class="read1 flex align-c" v-if="item.isRead">
                    <img :src="isRead_icon" alt="" />
                    <span>已读</span>
                  </div>
                  <div class="read1 unread flex align-c" v-else>
                    <img :src="unRead_icon" alt="" />
                    <span>未读</span>
                  </div>
                </div>
                <div class="name-time-wrapper flex">
                  <div>{{ item.createTime }}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="flex jus-c" style="padding: 160px 0;" v-else>暂无数据</div>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { NoticeModuleList, showRecord } from "@/api/ApiConfig";
import PersonMenu from "@/views/News/components/menu";
export default {
  components: {
    PersonMenu
  },
  data() {
    return {
      searchIcon: require("@/assets/images/icon/newsSearch.png"),
      inputValue: "",
      personIcon: require("@/assets/images/login/person.png"),
      isRead_icon: require("@/assets/images/icon/noticeRead.png"),
      unRead_icon: require("@/assets/images/icon/noticeUnread.png"),
      eye_icon: require("@/assets/images/home/icons/eye_icon.png"),
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      listData: [],

      tabNotice: [
        { label: "全部消息", value: "0" },
        { label: "已读消息", value: "1" },
        { label: "未读消息", value: "2" }
      ],
      number: "0"
    };
  },
  created() {
    let query = this.$route.query;
    if (query && query.inputValue) {
      this.inputValue = query.inputValue;
    }
    this.getData();
  },
  methods: {
    ToText(HTML) {
      var input = HTML;
      return input
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, "")
        .replace(/<[^>]+?>/g, "")
        .replace(/\s+/g, " ")
        .replace(/ /g, " ")
        .replace(/>/g, " ");
    },
    pathDetail(item) {
      this.doRead(item);
      this.$router.push({
        // name: "详情",
        // params: item
        path: "/newsDetail",
        query: item
      });
    },
    doRead(item) {
      showRecord({
        channel: "通知",
        id: item.id
      }).then(res => {});
    },
    // 分页导航
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
    getData() {
      let data = {
        channel: "通知",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        readState: this.number,
        title: this.inputValue
      };
      NoticeModuleList(data).then(res => {
        this.openLoading().close();
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        let listData = res.data.list;
        listData.map(item => {
          this.$set(
            item,
            "filterContent",
            item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
          );
        });
        this.listData = listData;
        this.pageTotal = res.data.total;
      });
    },
    // tab点击事件
    handleClick(item) {
      this.number = item.value;
      this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
.notifyMessage {
  padding-top: 109px;
  .Message {
    width: 970px;
  }
  ul {
    list-style-type: none;
  }
  .input {
    border: 1px solid #e5e5e5;
    border-radius: 19px;
    /deep/.el-input {
      input {
        border-radius: 19px;
        height: 32px;
        border: none;
        outline: none;
      }
    }
  }
  .search-wrapper {
    height: 38px;
    background: linear-gradient(135deg, #28b9f5, #3989eb);
    border-radius: 0 19px 19px 0;
    line-height: 34px;
    padding: 0 19px 0 13px;
    cursor: pointer;
    img {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
  }
  .menu-news {
    padding: 30px 20px;
    li {
      width: 110px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
      margin-right: 30px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0a1624;
      cursor: pointer;
    }
    .blue {
      background: rgba(57, 137, 235, 0.4);
      border: 1px solid #3989eb;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #3989eb;
    }
  }
  article {
    padding: 29px 40px 40px;
    margin-bottom: 40px;
    background: #ffffff;
    border-radius: 8px;
    .title-wrapper {
      border-bottom: 1px solid #e5e5e5;
      .name {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        border-bottom: 4px solid #3989eb;
        padding-bottom: 20px;
        margin-right: 18px;
      }
      .num {
        font-size: 16px;
        font-weight: 400;
        color: #3989eb;
        line-height: 40px;
      }
    }
    ul {
      li {
        margin-bottom: 41px;
        color: #666666;
        cursor: pointer;
        .banner {
          width: 220px;
          height: 140px;
          border-radius: 8px;
          margin-right: 40px;
        }
        .article-content {
          border-bottom: 1px solid #e5e5e5;
          padding: 10px 0 32px;
          min-width: 70%;
          .article-title {
            font-size: 16px;
            color: #333333;
            margin-bottom: 13px;
          }
          .articles {
            font-size: 14px;
            color: #666666;
            margin-bottom: 22px;
          }
          .read-wrapper {
            font-family: Tensentype RuiHeiJ-W4;
            font-weight: 500;
            color: #333e55;
            img {
              vertical-align: middle;
              margin-right: 7px;
            }
            .read1 {
              margin-right: 40px;
              font-size: 14px;
              font-family: Tensentype RuiHeiJ-W4;
              font-weight: 500;
              color: #3989eb;
              img {
                width: 15px;
                height: 15px;
              }
            }
            .unread {
              color: #909aa7;
            }
            .read2 {
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
          .name-time-wrapper {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
