import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { Loading } from "element-ui";
// import request from './api/request.js'
import * as echarts from "echarts";
import vueSeamlessScroll from "vue-seamless-scroll";
import ajax from "./api/ajax.js";
import { ajaxForm } from "./api/ajaxForm.js";
import "./routerOperation.js";

Vue.prototype.openLoading = function() {
  const loading = this.$loading({
    // 声明一个loading对象
    lock: true, // 是否锁屏
    text: "正在加载...", // 加载动画的文字
    spinner: "el-icon-loading", // 引入的loading图标
    background: "rgba(0, 0, 0, 0.3)", // 背景颜色
    target: ".sub-main", // 需要遮罩的区域
    body: true,
    customClass: "mask" // 遮罩层新增类名
  });
  setTimeout(function() {
    // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close(); // 关闭遮罩层
  }, 5000);
  return loading;
};
Vue.prototype.$ajax = ajax;
Vue.prototype.$ajaxForm = ajaxForm;
Vue.use(vueSeamlessScroll);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
// Vue.prototype.$request = request;
Vue.use(ElementUI, { zIndex: 30000 });

// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   if (to.matched.length != 0) {
//     if (to.meta.requireAuth) {
//       // 判断该路由是否需要登录权限
//       if (localStorage.getItem("pcToken")) {
//         next();
//       } else {
//         next({
//           path: "/",
//           query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
//         });
//       }
//     } else {
//       next();
//     }
//   } else {
//     next({
//       path: "/",
//       query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
//     });
//   }
// });
router.afterEach((to, from) => {
  //将滚动条恢复到最顶端
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
