<template>
  <div class="login">
    <div class="content flex flex-column jc-between ai-normal">
      <div class="logo-img-wrapper">
        <div class="logo-wrapper flex align-c">
          <img :src="logoIcon" alt="" class="logo" />
        </div>
      </div>
      <div class="login_form">
        <div class="form-content">
          <div class="title">登录教学中心平台</div>
          <div class="subTitle">实名制账户保护您的个人隐私，记录您的教学下载课程，提供完美的解决方案</div>
          <div class="tab-wrapper" style="text-align: center;" v-if="['3', '4'].includes(number)">
            <div class="reset">
              重置密码
            </div>
          </div>
          <div class="tab-wrapper flex" v-else>
            <div
              class="main_tab"
              v-for="item in ruleCodes"
              :key="item.value"
              :label="item.value"
              @click="handleClick(item)"
              :class="number == item.value ? 'blue' : ''"
            >
              {{ item.label }}
            </div>
          </div>
          <!-- 忘记密码 -->
          <el-form
            :model="ruleFormPassword"
            :rules="rulesPassword"
            :hide-required-asterisk="true"
            ref="ruleFormPassword"
            :validate-on-rule-change="false"
            v-if="number === '3'"
          >
            <el-form-item prop="email">
              <el-input
                prefix-icon="el-icon-user-solid"
                v-model="ruleFormPassword.email"
                placeholder="请输入邮箱账号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="flex">
                <el-input
                  prefix-icon="el-icon-lock"
                  v-model="ruleFormPassword.code"
                  autocomplete="off"
                  placeholder="请输入验证码"
                  clearable
                ></el-input>
                <el-button class="codeBtn" :disabled="isDisabled" @click="getCode">{{
                  sendName
                }}</el-button>
              </div>
            </el-form-item>
            <div class="btn-wrapper btn-wrapper-between">
              <div @click="forgetBackBtn1">上一步</div>
              <div class="loginBtn" @click="forgetNextBtn('ruleFormPassword')">下一步</div>
            </div>
          </el-form>
          <el-form
            :model="ruleFormNewPassword"
            :rules="rulesNewPassword"
            :hide-required-asterisk="true"
            ref="ruleFormNewPassword"
            :validate-on-rule-change="false"
            v-if="number === '4'"
          >
            <el-form-item>
              <el-input
                prefix-icon="el-icon-lock"
                type="password"
                v-model="ruleFormNewPassword.Newpass"
                autocomplete="off"
                placeholder="请输入新密码"
                clearable
              ></el-input>
            </el-form-item>
            <div class="btn-wrapper btn-wrapper-between">
              <div @click="forgetBackBtn">上一步</div>
              <div class="loginBtn" @click="submitNewPassword('ruleFormNewPassword')">
                确定修改
              </div>
            </div>
          </el-form>
          <!-- 账号登录 -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            :hide-required-asterisk="true"
            ref="ruleForm"
            class="demo-ruleForm"
            :validate-on-rule-change="false"
            v-if="number === '1'"
          >
            <el-form-item prop="name">
              <el-input
                prefix-icon="el-icon-user-solid"
                v-model="ruleForm.name"
                placeholder="请输入账户/手机/邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item prop="pass">
              <el-input
                prefix-icon="el-icon-lock"
                type="password"
                v-model="ruleForm.pass"
                autocomplete="off"
                placeholder="请输入登录密码"
                clearable
              ></el-input>
            </el-form-item>
            <div class="btn-wrapper flex jus-b">
              <div class="loginBtn" @click="submitForm('ruleForm')">登 录</div>
              <div v-if="number === '1'" @click="touristLoginBtn">专家登录</div>
            </div>
            <div class="forget" @click="number = '3'">忘记密码</div>
          </el-form>
          <!-- 工号登录 -->
          <el-form
            :model="ruleFormJobID"
            :rules="rulesJobID"
            :hide-required-asterisk="true"
            ref="ruleFormJobID"
            class="demo-ruleForm"
            :validate-on-rule-change="false"
            v-if="number === '2'"
          >
            <el-form-item prop="name">
              <el-input
                prefix-icon="el-icon-user-solid"
                v-model="ruleFormJobID.name"
                placeholder="请输入工号/学号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="pass">
              <el-input
                prefix-icon="el-icon-lock"
                type="password"
                v-model="ruleFormJobID.pass"
                autocomplete="off"
                placeholder="请输入登录密码"
                clearable
              ></el-input>
            </el-form-item>
            <div class="btn-wrapper btn-wrapper-center">
              <div class="loginBtn" @click="submitForm('ruleFormJobID')">登 录</div>
            </div>
            <div class="forget" @click="number = '3'">忘记密码</div>
          </el-form>
        </div>
      </div>
      <footer>
        <div class="tel flex jc-centr ai-center">
          <img class="icon" src="../assets/images/login/tel.png" />
          <div class="txt">技术支持: 杭州辰有智能科技有限公司 服务热线: 15382305057</div>
        </div>
        <div class="copyright">Copyright © 杭州辰有智能科技有限公司 版权所有 浙ICP备2023033855号-2</div>
      </footer>
    </div>
  </div>
</template>
<script>
import { loginPc, sendEmailCode, forgetUpPwd } from "@/api/ApiConfig";
import * as rules from "@/utils/rules";
import md5 from "js-md5";

export default {
  components: {},
  data() {
    return {
      logoIcon: require("@/assets/images/login/logo.png"),
      ruleCodes: [
        { label: "账号登录", value: "1" },
        { label: "工号/学号登录", value: "2" }
      ],
      number: "1",
      //账号登录
      ruleForm: {
        name: "",
        pass: ""
      },
      rules: {
        name: [{ required: true, message: "请输入账户/手机/邮箱", trigger: "blur" }],
        pass: [{ required: true, message: "请输入登录密码", trigger: "blur" }]
      },
      //工号登录
      ruleFormJobID: {
        name: "",
        pass: ""
      },
      rulesJobID: {
        name: [{ required: true, message: "请输入工号/学号", trigger: "blur" }],
        pass: [{ required: true, message: "请输入登录密码", trigger: "blur" }]
      },
      //重置密码
      ruleFormPassword: {
        email: "",
        code: ""
      },
      rulesPassword: {
        email: [
          { required: true, message: "请输入邮箱账号", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱账号", trigger: ["blur", "change"] }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
      //重置密码 确认修改密码
      ruleFormNewPassword: {
        Newpass: ""
      },
      rulesNewPassword: {
        Newpass: [{ required: true, message: "请输入新密码", trigger: "blur" }]
      },
      sendName: "获取验证码",
      isDisabled: false,
      timer: null,
      second: 60
    };
  },
  watch: {
    number: {
      //为了切换不把验证带过去
      handler(newValue, oldValue) {
        if (newValue == "1") {
          this.$nextTick(async () => {
            await this.$refs["ruleForm"].clearValidate();
          });
        }
        if (newValue == "3") {
          this.$nextTick(async () => {
            await this.$refs["ruleFormPassword"].clearValidate();
          });
        } else if (newValue == "4") {
          this.$nextTick(async () => {
            await this.$refs["ruleFormNewPassword"].clearValidate();
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  methods: {
    // tab点击事件
    handleClick(item) {
      this.number = item.value;
      //为了切换不把验证带过去
      switch (item.value) {
        case "1":
          this.$nextTick(async () => {
            await this.$refs["ruleForm"].clearValidate();
          });
          break;
        case "2":
          this.$nextTick(async () => {
            await this.$refs["ruleFormJobID"].clearValidate();
          });
          break;

        default:
          break;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.openLoading();
          let data = {
            userId:
              this.number == "1"
                ? this.ruleForm.name
                : this.number == "2"
                ? this.ruleFormJobID.name
                : "",
            userPwd:
              this.number == "1"
                ? md5(this.ruleForm.pass).toUpperCase()
                : this.number == "2"
                ? md5(this.ruleFormJobID.pass).toUpperCase()
                : ""
          };
          loginPc(data).then(res => {
            this.openLoading().close();
            if (res.code !== 200) {
              this.$message.error(res.msg);
              return;
            }
            localStorage.setItem("pcToken", res.data.token);
            this.touristLoginBtn();
          });
        } else {
          return false;
        }
      });
    },
    getCode() {
      if (!this.ruleFormPassword.email) {
        this.$message.warning("请先输入邮箱！");
        return;
      }
      const reg1 = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!reg1.test(this.ruleFormPassword.email)) {
        this.$message.warning("请填写正确的邮箱格式！");
        return;
      }
      this.openLoading();
      sendEmailCode({
        email: this.ruleFormPassword.email
      }).then(res => {
        this.openLoading().close();
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.timer = setInterval(() => {
          this.second--;
          this.sendName = this.second + "秒后重发";
          this.isDisabled = true;
          if (this.second < 0) {
            this.sendName = "获取验证码";
            this.isDisabled = false;
            this.second = 60;
            clearInterval(this.timer);
          }
        }, 1000);
        this.$message.success("验证码发送成功");
      });
    },
    forgetBackBtn1() {
      this.number = "1"; //邮箱的上一步
    },
    forgetBackBtn() {
      this.number = "3"; //确认修改的上一步
    },
    forgetNextBtn(formName) {
      // this.number = "4";
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.number = "4";
        } else {
          return false;
        }
      });
    },
    //确认修改
    submitNewPassword(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.openLoading();
          forgetUpPwd({
            code: this.ruleFormPassword.code,
            email: this.ruleFormPassword.email,
            newPwd: md5(this.ruleFormNewPassword.Newpass).toUpperCase()
          }).then(res => {
            this.openLoading().close();
            if (res.code !== 200) {
              this.$message.error(res.msg);
              return;
            }
            this.number = "1";
            this.$message.success("密码修改成功");
          });
        } else {
          return false;
        }
      });
    },
    touristLoginBtn() {
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #F0F2F5 url("../assets/images/login/bg.png") no-repeat right center;
  background-size: 62% 100%;

  .content {
    width: 38%;
    height: 100%;
    .logo-img-wrapper {
      .logo-wrapper {
        .logo {
          width: 362px;
          height: 50px;
          margin: 32px 0 0 40px;
        }
      }
    }
    .login_form {
      width: 432px;
      margin: 0 auto;
      .title {
        line-height: 47px;
        font-size: 40px;
        color: #000000;
        margin-top: 16px;
      }
      .subTitle {
        line-height: 20px;
        font-size: 14px;
        color: #999999;
        margin-top: 16px;
      }
      .form-content {
        .tab-wrapper {
          font-size: 18px;
          margin: 24px 0;
          color: #555555;
          border-bottom: dashed 2px #eee;
          .main_tab {
            cursor: pointer;
            margin-right: 40px;
            line-height: 85px;
            margin-bottom: -2px;
          }
          .blue {
            color: #000;
            border-bottom: solid 2px #467AE5;
          }
          .reset {
            font-size: 18px;
            color: #555555;
            line-height: 25px;
          }
        }
        .codeBtn {
          margin-left: 12px;
        }
        .forget {
          font-size: 14px;
          color: #327CED;
          line-height: 20px;
          cursor: pointer;
          text-align: right;
          margin: 24px 0 12px;
        }
        .btn-wrapper-center {
          display: flex;
          justify-content: center;
        }
        .btn-wrapper-between {
          display: flex;
          justify-content: space-between;
        }
        .btn-wrapper {
          div {
            width: 201px;
            height: 52px;
            line-height: 52px;
            background: #327CED;
            border-radius: 26px;
            text-align: center;
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }
      /deep/.el-form-item__content {
        .el-input__inner {
          border: #1890ff;
          height: 50px;
          background: #ffffff;
          border: 1px solid #1890ff;
          border-radius: 6px;
          font-size: 16px;
        }
      }
      /deep/.el-form-item {
        margin-bottom: 40px;
      }
    }
    footer {
      padding: 15px 32px;
      .tel {
        .icon {
          width: 14px;
          height: 13px;
        }
        .txt {
          margin-left: 10px;
          font-size: 14px;
          color: #327CED;
        }
      }
      .copyright {
        font-size: 14px;
        color: #999999;
        margin-top: 10px;
      }
    }
  }
}
</style>
