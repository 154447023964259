<template>
  <div class="questions">
    <div class="classify">
      <div v-for="(item, index) in types" :key="index" class="wrap flex jc-between ai-center">
        <div class="label">{{ item.name }}</div>
        <div class="list flex-1 flex ai-center">
          <div v-for="(child, idx) in item.list" :key="idx" :class="['item cursor-pointer', { 'actived': item.actived === idx }]" @click="handleTypeClick(item, idx)">{{ child.name }}</div>
        </div>
      </div>
    </div>
    <div class="table">
      <div v-for="(item, index) in list" :key="index" class="wrap">
        <div class="title">{{ item.title }}</div>
        <div class="answer">
          <div v-for="(child, idx) in item.answer" :key="idx" class="item">{{ child }}</div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 40, 50, 100]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <div class="btns text-align-center">
          <el-button type="primary" class="submit" @click="handleDownload()">下载</el-button>
        </div>
      </div>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      types: [
        {
          id: 0,
          name: '专业大类：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '计算机'
            },
            {
              id: 2,
              name: '医学'
            },
            {
              id: 3,
              name: '音乐'
            },
            {
              id: 4,
              name: '建筑'
            }
          ]
        },
        {
          id: 2,
          name: '课程类型：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '编程'
            },
            {
              id: 2,
              name: '3D建模'
            },
            {
              id: 3,
              name: '设计'
            },
            {
              id: 4,
              name: '网络'
            }
          ]
        },
        {
          id: 3,
          name: '课程系列：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '建筑领域'
            },
            {
              id: 2,
              name: '服务领域'
            },
            {
              id: 3,
              name: '计算机领域'
            },
            {
              id: 4,
              name: '金融领域'
            }
          ]
        },
        {
          id: 4,
          name: '题目类型：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '单选题'
            },
            {
              id: 2,
              name: '多选题'
            },
            {
              id: 3,
              name: '判断题'
            },
            {
              id: 4,
              name: '操作题'
            }
          ]
        }
      ],
      page: 1,
      size: 10,
      total: 10,
      list: [
        {
          id: 1,
          type: 0,
          title: '1.虚拟仿真实验课堂中，对于“在两列波重叠的区域里，质点是否会随机参与一个振动”的说法，正确的选项是？',
          answer: [
            'A. 对',
            'B. 错'
          ],
          right_key: 'A. 对'
        },
        {
          id: 2,
          type: 0,
          title: '2.在虚拟实验中，下列哪项技术允许在单一物理服务器上运行多个独立的操作系统？',
          answer: [
            'A. 云计算',
            'B. 虚拟化技术',
            'C. 容器技术',
            'D. 分布式系统'
          ],
          right_key: 'A. 云计算'
        },
        {
          id: 3,
          type: 0,
          title: '3.关于虚拟实验中的“虚拟机”，下列说法正确的是？',
          answer: [
            'A. 虚拟机与真实机在硬件上完全相同',
            'B. 虚拟机使用的存储文件通常是镜像文件',
            'C. 虚拟机无法感知虚拟硬件',
            'D. 虚拟机无法实现与真实机相同的性能'
          ],
          right_key: 'A. 虚拟机与真实机在硬件上完全相同'
        },
        {
          id: 4,
          type: 0,
          title: '4.在虚拟实验环境中，宿主机和客户机之间的关系是？',
          answer: [
            'A. 宿主机控制客户机',
            'B. 客户机控制宿主机',
            'C. 宿主机和客户机相互独立',
            'D. 宿主机提供资源，客户机使用资源'
          ],
          right_key: 'D. 宿主机提供资源，客户机使用资源'
        },
        {
          id: 5,
          type: 0,
          title: '5.下列哪项不是虚拟实验相对于传统实验的优势？',
          answer: [
            'A. 突破空间约束',
            'B. 超越时间限制',
            'C. 安全性高',
            'D. 无需实验器材'
          ],
          right_key: 'D. 无需实验器材'
        }
      ]
    };
  },
  created() {
    this.getData()
  },
  activated() {
  },
  methods: {
    handleTypeClick(item, idx) {
      item.actived = idx
    },
    getData() {
      // queryStudyDetail({
      //   page: this.data.page,
      //   size: this.data.size
      // }).then(res => {
      //   if (res.code === 200) {
      //     this.list = res.data.rows || [];
      //     this.total = res.data.total
      //   }
      // });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    handleDownload() {

    }
  }
};
</script>
<style lang="scss">
.questions {
  padding-top: 109px;
  .classify {
    width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 12px;
    padding: 20px 30px;
    margin: 0 auto;
    .wrap {
      padding: 16px 0;
      .label {
        width: 90px;
        height: 25px;
        font-weight: bold;
        font-size: 18px;
        color: #327CED;
        line-height: 25px;
        text-align: left;
        font-style: normal;
      }
      .item {
        font-size: 16px;
        color: #697B95;
        line-height: 38px;
        padding: 0 16px;
        &.actived {
          background: #327CED;
          border-radius: 6px;
          color: #FFFFFF;
        }
      }
    }
  }
  .table {
    width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 12px;
    margin: 30px auto;
    padding: 10px 30px;
    .wrap {
      margin: 20px 0;
      .title {
        font-weight: bold;
        font-size: 22px;
        color: #254B88;
        line-height: 37px;
      }
      .answer {
        padding-left: 15px;
      }
      .item {
        font-size: 18px;
        color: #254B88;
        line-height: 37px;
      }
    }
  }
  .pagination {
    margin-top: 20px;
  }
  .btns {
    margin: 30px 0;
  }
}
</style>
