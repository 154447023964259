<template>
  <div class="report-pager">
    <div class="report-container">
      <div class="title flex jc-between ai-center">
        <div class="name">报告</div>
        <div class="btns flex ai-center">
          <el-button :type="type === 1 ? 'primary' : 'default'" @click="handleTypeClick(1)">填写报告</el-button>
          <el-button :type="type === 0 ? 'primary' : 'default'" @click="handleTypeClick(0)">上传报告</el-button>
        </div>
      </div>
      <div class="report-content">
        <div v-if="type === 1">
          <table>
            <tr>
              <th>实验名称</th>
              <td :colspan="3">声乐演唱多场景设计及应用虚拟仿真实验</td>
            </tr>
            <tr>
              <th>学校</th>
              <td>浙江传媒学院</td>
              <th>专业</th>
              <td>产品设计</td>
            </tr>
            <tr>
              <th>姓名</th>
              <td>张思</td>
              <th>学好</th>
              <td>463800</td>
            </tr>
            <tr>
              <th>实验目的</th>
              <td :colspan="3">
                <el-input v-model="form.aaa" placeholder="请输入实验目的" type="textarea" :rows="4"></el-input>
              </td>
            </tr>
            <tr>
              <th>实验内容</th>
              <td :colspan="3">
                <el-input v-model="form.bbb" placeholder="请输入实验内容" type="textarea" :rows="8"></el-input>
              </td>
            </tr>
            <tr>
              <th :rowspan="5">实验步骤</th>
              <td>步骤名</td>
              <td :colspan="2">操作</td>
            </tr>
            <tr>
              <th>步骤名1</th>
              <td :colspan="2">操作1</td>
            </tr>
            <tr>
              <th>步骤名2</th>
              <td :colspan="2">操作2</td>
            </tr>
            <tr>
              <th>步骤名3</th>
              <td :colspan="2">操作3</td>
            </tr>
            <tr>
              <th>步骤名4</th>
              <td :colspan="2">操作4</td>
            </tr>
            <tr>
              <th>实验总结</th>
              <td :colspan="3">
                <el-input v-model="form.ccc" placeholder="请输入实验总结" type="textarea" :rows="12"></el-input>
              </td>
            </tr>
          </table>
        </div>
        <div v-else class="flex jc-center">
          <el-upload
            :headers="{ userId }"
            class="avatar-uploader"
            action="/backAdmin/upload/uploadPicture"
            accept="image/*"
            :show-file-list="false"
            :on-success="httpRequestSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="form.file" :src="form.file" class="avatar">
            <img v-else src="../images/upload-icon.png" class="avatar-uploader-icon">
            <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
          </el-upload>
        </div>
        <div v-if="is_teacher" class="teacherForm">
          <el-form
            :model="form"
            :rules="rules"
            ref="formRef"
            label-position="top"
          >
            <el-form-item label="评论" prop="ddd">
              <el-input v-model="form.bbb" placeholder="请输入评论" type="textarea" :rows="4"></el-input>
            </el-form-item>
            <el-form-item label="打分" prop="eee">
              <el-input v-model="form.eee" placeholder="请输入分数"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btns flex jc-center">
          <el-button type="primary" @click="handleSubmit()">提交</el-button>
          <el-button @click="handleCancel(0)">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      type: 1,
      form: {
        aaa: '',
        bbb: '',
        ccc: '',
        file: '',
        ddd: '',
        eee: ''
      },
      rules: {

      },
      userId: '',
      is_teacher: true
    };
  },
  watch: {
    pager() {
    },
    params() {
    }
  },
  mounted() {
  },
  methods: {
    handleTypeClick(type) {
      this.type = type
    },
    async httpRequestSuccess(res) {
      this.form.ccc = `https://ai.cyzntech.com/File/${res.data}`;
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    handleSubmit() {

    },
    handleCancel() {

    }
  }
};
</script>
<style lang="scss" scoped>
.report-pager {
  background-color: #F4F5F8;
  overflow: hidden;
  .report-container {
    width: 1200px;
    margin: 50px auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 20px;
    opacity: 0.86;
    padding: 20px 30px;
    .title {
      .name {
        font-weight: bold;
        font-size: 20px;
        color: #575757;
        line-height: 37px;
      }
    }
    .report-content {
      margin: 20px 0;
    }
    table {
      width: 100%;
      margin: 20px 0;
      border-radius: 20px;
      border: 1px solid #979797;
      border-collapse: collapse;
      line-height: 48px;
      th, td {
        border: 1px solid #979797;
        text-align: center;
        font-size: 14px;
        color: #575757;
        /deep/ .el-textarea__inner {
          border: none;
        }
      }
    }
    /deep/.avatar-uploader {
      .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader-icon {
        width: 268px;
        height: 190px;
      }
      .avatar {
        width: 268px;
        height: auto;
        display: block;
      }
    }
    .teacherForm {
      /deep/ .el-form--label-top .el-form-item__label {
        font-weight: bold;
        font-size: 20px;
        color: #575757;
        line-height: 36px;
      }
      /deep/ .el-input__inner, /deep/ .el-textarea__inner {
        border-radius: 18px;
        border: 1px dashed #979797;
        font-size: 16px;
        color: #999999;
        line-height: 36px;
        padding: 9px 16px;
        box-sizing: content-box;
      }
    }
  }
}
</style>
