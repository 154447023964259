<template>
  <div class="PersonalCenter flex">
    <PersonMenu />
    <div class="PersonalCenter-container">
      <Head />
      <PortraitMore
        :isShowCollect="false"
        :listData="listData"
        :pageTotal="pageTotal"
        @getPageSize="getPageSize"
        @getPageIndex="getPageIndex"
      />
      <HorizontalImgText
        :hideHeader="true"
        :isShowCollect="false"
        type="experiment"
        :pageTotal="pageTotal1"
        :listData="listData1"
        @getPageSize="getPageSize1"
        @getPageIndex="getPageIndex1"
      />
      <PortraitOne
        :hideHeader="true"
        :isShowCollect="false"
        type="course"
        :pageTotal="pageTotal2"
        :listData="listData2"
        @getPageSize="getPageSize2"
        @getPageIndex="getPageIndex2"
      />
    </div>
  </div>
</template>

<script>
import { MyView } from "@/api/ApiConfig";
import Head from "@/views/PersonalCenter/components/head";
import PersonMenu from "@/views/PersonalCenter/components/menu";
import HorizontalImgText from "@/components/HorizontalImgText";
import PortraitMore from "@/components/PortraitMore";
import PortraitOne from "@/components/PortraitOne";
export default {
  components: {
    Head,
    PersonMenu,
    HorizontalImgText,
    PortraitMore,
    PortraitOne
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      pageTotal1: 0,
      pageTotal2: 0,
      listData: [],
      listData1: [],
      listData2: [],
      queryParams1: {
        channel: "实验",
        pageIndex: 1,
        pageSize: 10,
        type: ""
      },
      queryParams2: {
        channel: "课程",
        pageIndex: 1,
        pageSize: 10,
        type: ""
      }
    };
  },
  watch: {},
  created() {
    this.getData(); //新闻的浏览记录
    this.getExperModuleList(); // 实验
    this.getStudyModuleList(); //课程
  },
  mounted() {},
  methods: {
    getPageIndex(val) {
      this.pageIndex = val;
      this.getData();
    },
    getPageSize(val) {
      this.pageSize = val;
      this.getData();
    },
    getPageIndex1(val) {
      this.queryParams1.pageIndex = val;
      this.getExperModuleList();
    },
    getPageSize1(val) {
      this.queryParams1.pageSize = val;
      this.getExperModuleList();
    },
    getPageIndex2(val) {
      this.queryParams2.pageIndex = val;
      this.getStudyModuleList();
    },
    getPageSize2(val) {
      this.queryParams2.pageSize = val;
      this.getStudyModuleList();
    },
    getData() {
      this.openLoading();
      MyView({
        channel: "新闻",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        this.openLoading().close();
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.listData = res.data.list;
        this.pageTotal = res.data.total;
      });
    },
    // 实验
    getExperModuleList() {
      MyView(this.queryParams1).then(res => {
        if (res.code === 200) {
          let listData = res.data.list;
          listData.map(item => {
            this.$set(
              item,
              "filterContent",
              item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
            );
          });
          this.listData1 = listData;
          this.pageTotal1 = res.data.total;
        }
      });
    },
    // 课程
    getStudyModuleList() {
      MyView(this.queryParams2).then(res => {
        if (res.code === 200) {
          let listData = res.data.list;
          listData.map(item => {
            this.$set(
              item,
              "filterContent",
              item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
            );
          });
          this.listData2 = listData;
          this.pageTotal2 = res.data.total;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.PersonalCenter {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 109px;
  ul {
    list-style-type: none;
  }
  .menu-wrapper {
    padding: 37px 0;
    margin-right: 50px;
    position: relative;
    li {
      margin-bottom: 25px;
      cursor: pointer;
      line-height: 44px;
      img {
        width: 15px;
        height: 15px;
        vertical-align: middle;
        margin: 0 13px 0 30px;
      }
    }
    .blue {
      width: 180px;
      height: 44px;
      background: linear-gradient(135deg, #28b9f5, #3989eb);
      border-radius: 4px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 44px;
    }
  }
  .PersonalCenter-container {
    width: 970px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    header {
      width: 970px;
      height: 160px;
      background: linear-gradient(135deg, #28b9f5, #3989eb);
      box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
      border-radius: 8px;
      padding: 40px;
      margin-bottom: 40px;
      .img-name-wrapper {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        .name-wrapper {
          margin-left: 31px;
          .name {
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 13px;
          }
        }
      }
      .data-wrapper {
        .phone-wrapper {
          text-align: center;
          width: 140px;
          .name {
            margin-bottom: 22px;
          }
        }
        .line {
          width: 1px;
          height: 60px;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(242, 249, 254, 0.8) 50%,
            rgba(255, 255, 255, 0.01) 100%
          );
        }
      }
    }
  }
  /deep/.HorizontalImgText article {
    padding: 29px 40px 40px;
    margin-bottom: 40px;
    background: #ffffff;
    border-radius: 8px;
    .recommend_list {
      padding: 39px 0 0 10px;
    }
  }
  /deep/.PortraitOne article {
    padding: 29px 40px 40px;
    margin-bottom: 40px;
    background: #ffffff;
    border-radius: 8px;
    .recommend_list {
      padding: 39px 0 0 0;
    }
  }
}
</style>
