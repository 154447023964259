<template>
  <div class="setting-pager">
    <div class="setting-container">
      <el-form
        :model="form"
        :rules="rules"
        ref="formRef"
        label-position="top"
      >
        <el-form-item label="公司名称" prop="aaa">
          <el-input v-model="form.aaa" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="备案编号" prop="bbb">
          <el-input v-model="form.bbb" placeholder="请输入备案内容"></el-input>
        </el-form-item>
        <el-form-item label="上传logo" prop="ccc">
          <div class="flex jc-center">
            <el-upload
              :headers="{ userId }"
              class="avatar-uploader"
              action="/backAdmin/upload/uploadPicture"
              accept="image/*"
              :show-file-list="false"
              :on-success="httpRequestSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="form.ccc" :src="form.ccc" class="avatar">
              <img v-else src="../images/upload-icon.png" class="avatar-uploader-icon">
              <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
            </el-upload>
          </div>
        </el-form-item>
        <div class="btns flex jc-center ai-center">
          <el-button size="small" type="primary" class="btn" @click="onSubmit()">提交</el-button>
          <el-button size="small" class="btn" @click="onCancel()">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      userId: '',
      form: {
        aaa: '',
        bbb: '',
        ccc: ''
      },
      rules: {
        
      }
    };
  },
  watch: {
    pager() {
    },
    params() {
    }
  },
  mounted() {
  },
  methods: {
    async httpRequestSuccess(res) {
      this.form.ccc = `https://ai.cyzntech.com/File/${res.data}`;
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("图片大小不能超过 5MB!");
      }
      return isLt5M;
    }
  }
};
</script>
<style lang="scss" scoped>
.setting-pager {
  background-color: #F4F5F8;
  overflow: hidden;
  .setting-container {
    width: 1200px;
    margin: 50px auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 20px;
    opacity: 0.86;
    padding: 30px;
  }
}
/deep/ .el-form--label-top .el-form-item__label {
  font-weight: bold;
  font-size: 22px;
  color: #575757;
  line-height: 37px;
}
/deep/ .el-input__inner {
  height: 54px;
  border-radius: 18px;
  border: 1px dashed #979797;
  font-size: 16px;
  color: #999999;
  line-height: 54px;
}
/deep/.avatar-uploader {
  .el-upload {
    // border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    width: 268px;
    height: 190px;
  }
  .avatar {
    width: 268px;
    height: auto;
    display: block;
  }
}
</style>
