import axios from 'axios'
import { BASEURL } from './ApiConfig'

export const ajaxForm = (config) => {
  let param = null
  if (config.base !== undefined) {
    if (config.params === undefined || config.params === null) {
      param = {
        requestPara: JSON.stringify(config.params)
      }
    } else {
      let token = window.localStorage.getItem('token')
      // let token = "RYfUOnOENrNK5GXQ_58E89gn78dHdKGpStbv3ZAAI6rRoJgbxPt2T3mk-6-O4gobPnmv-3Sx4Pr29tFqS-5vUg"

      let target = { token, UNID: '', areaId: 0, accountType: 'XJ', apiType: 'PC', appVersion: '1.0.2',waterCorpId: 3, }
      // target.waterCorpId =  window.localStorage.getItem('waterCorpId');

      Object.assign(config.params, target)
      if (config.type === 'get') {
        param = {
          requestPara: JSON.stringify(config.params)
        }
      } else {
        param = `requestPara=${JSON.stringify(config.params)}`
        param = param.replace(/\+/g, "%2B");
        param = param.replace(/\&/g, "%26");
      }
    }
  } else {
    param = config.param
  }

  let configObj = {
    method: config.type || 'get',
    baseURL: BASEURL,
    url: config.url,
    responseType: config.resType || 'json',
    timeout: 500000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials: config.withCredentials || false,
    progress: function (progressEvent) {
      // 做任何你想做的关于进度的事
    }
  }
  if (config.type === 'get') {
    configObj['params'] = param || {}
  } else {
    configObj['data'] = param || {}
  }
  axios(configObj)
    .then(res => {
      if (res.status === 200) {
        if (res.data.status === 11) {
          // message.error(res.data.message)
          config.successFn(res.data, res)
        } else {
          config.successFn(res.data, res)
        }
      }
    })
    .catch(e => {
      config.failFn(e)
    })
}