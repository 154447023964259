<template>
  <ul class="menu2-wrapper">
    <li
      class="main_tab"
      v-for="item in ruleCodes"
      :key="item.type"
      :label="item.type"
      @click="handleClick(item)"
      :class="type == item.type ? 'blue' : ''"
    >
      <img v-if="type == item.type" :src="item.unCheckIcon" alt="" />
      <img v-else :src="item.CheckIcon" alt="" />
      <span>{{ item.type }}</span>
    </li>
  </ul>
</template>

<script>
import { studyType } from "@/api/ApiConfig.js";

export default {
  components: {},
  data() {
    return {
      ruleCodes: [],
      type: "全部课程"
    };
  },
  created() {
    this.getStudyType();
  },
  activated() {
    this.getStudyType();
  },
  methods: {
    // 菜单
    getStudyType() {
      studyType({
        channel: "课程"
      }).then(res => {
        if (res.code === 200) {
          let ruleCodes = [
            {
              type: "全部课程",
              CheckIcon: require("@/assets/images/icon/all-blue.png"),
              unCheckIcon: require("@/assets/images/icon/all-gray.png")
            },
            {
              type: "热门课程",
              CheckIcon: require("@/assets/images/icon/hot-blue.png"),
              unCheckIcon: require("@/assets/images/icon/hot-gray.png")
            },
            {
              type: "最新课程",
              CheckIcon: require("@/assets/images/icon/top-blue.png"),
              unCheckIcon: require("@/assets/images/icon/top-gray.png")
            }
          ]
          ruleCodes.push(...res.data.map(item => {
            return {
              type: item.type,
              CheckIcon: require("@/assets/images/icon/hot-blue.png"),
              unCheckIcon: require("@/assets/images/icon/hot-gray.png")
            };
          }))
          this.ruleCodes = ruleCodes
        }
      });
    },
    // tab点击事件
    handleClick(item) {
      this.type = item.type;
      this.$emit("getData", item.type);
    }
  }
};
</script>
<style lang="scss" scoped>
.main_tab {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
</style>
