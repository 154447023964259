<template>
  <div class="score-pager">
    <div class="detail">
      <div class="inner">
        <div class="name">综合评分</div>
        <div class="synthesize flex ai-center">
          <div class="score-total">{{ divide }}<span class="unit">分</span></div>
          <div class="stars">
            <div class="tit flex jc-between ai-center">
              <div class="label">总评分</div>
              <div class="total">共{{ num }}人评价</div>
            </div>
            <el-rate v-model="average" disabled :colors="['#F0A256', '#F0A256', '#F0A256']" class="rate"></el-rate>
          </div>
          <div class="star-wrap flex-1 flex jc-between ai-center">
            <div class="star-item flex-1">
              <div class="label">实验内容</div>
              <el-rate v-model="content" disabled :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
            </div>
            <div class="star-item flex-1">
              <div class="label">实验特色</div>
              <el-rate v-model="feature" disabled :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
            </div>
            <div class="star-item flex-1">
              <div class="label">实验通过率</div>
              <el-rate v-model="passingRate" disabled :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="flex jc-between ai-center">
            <div class="section flex-1">
              <div class="name">发表评价</div>
              <div class="cont">
                <el-input type="textarea" :rows="4" placeholder="请输入评价内容" v-model="form.evaluate" class="textarea" />
              </div>
            </div>
            <div class="section section2 flex-1">
              <div class="name goScore">进行评分</div>
              <div class="cont flex ai-center">
                <div class="line"></div>
                <div class="flex-1 flex flex-wrap ai-center">
                  <div class="star-item flex ai-center">
                    <div class="label">实验内容</div>
                    <el-rate v-model="form.content" :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
                  </div>
                  <div class="star-item flex ai-center">
                    <div class="label">实验特色</div>
                    <el-rate v-model="form.feature" :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
                  </div>
                  <div class="star-item flex ai-center">
                    <div class="label">实验通过率</div>
                    <el-rate v-model="form.passingRate" :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btns text-align-center">
            <el-button type="primary" class="submit" @click="handleSubmit()">提交</el-button>
          </div>
        </div>
        <div class="name">学员评价</div>
        <div v-if="list.length === 0" class="empty">暂无评价</div>
        <div class="comment-wrap">
          <div v-for="(item, index) in list" :key="index" class="comment-item">
            <div class="head flex jc-between ai-center">
              <div class="user flex ai-center">
                <img :src="item.pic" alt="" class="photo">
                <div class="username">{{ item.userName }}</div>
              </div>
              <div class="score flex ai-center">
                <div class="star-item flex ai-center">
                  <div class="label">实验内容</div>
                  <el-rate v-model="item.content" disabled :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
                </div>
                <div class="star-item flex ai-center">
                  <div class="label">实验特色</div>
                  <el-rate v-model="item.feature" disabled :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
                </div>
                <div class="star-item flex ai-center">
                  <div class="label">实验通过率</div>
                  <el-rate v-model="item.passingRate" disabled :colors="['#467AE5', '#467AE5', '#467AE5']" class="rate"></el-rate>
                </div>
              </div>
            </div>
            <div class="comment">{{ item.evaluate }}</div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 40, 50, 100]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { comprehensiveDivide, userEvaluateList, inStudyEvaluate } from "@/api/ApiConfig.js";

export default {
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      num: 0,
      divide: 0,
      average: 0,
      content: 0,
      feature: 0,
      passingRate: 0,
      page: 1,
      size: 10,
      total: 0,
      list: [],
      form: {
        content: 0,
        feature: 0,
        passingRate: 0,
        evaluate: ''
      }
    };
  },
  mounted() {
    this.comprehensiveDivide()
    this.getEvaluateList()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async comprehensiveDivide() {
      const res = await comprehensiveDivide({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.num = Number(res.data.num) || 0
        this.divide = Number(res.data.divide) || 0
        this.content = Number(res.data.content) || 0
        this.feature = Number(res.data.feature) || 0
        this.passingRate = Number(res.data.passingRate) || 0
        this.average =  Number(((this.content + this.feature + this.passingRate) / 3).toFixed(1))
      }
    },
    getEvaluateList() {
      userEvaluateList({
        id: this.laboratory_id,
        pageIndex: this.page,
        pageSize: this.size
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data.list.map(item => {
            item.content = Number(item.content)
            item.feature = Number(item.feature)
            item.passingRate = Number(item.passingRate)
            return item
          });
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getEvaluateList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getEvaluateList();
    },
    handleSubmit() {
      inStudyEvaluate({
        id: this.laboratory_id,
        ...this.form
      }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.getEvaluateList();
          this.comprehensiveDivide();
        } else {
          this.$message.error(res.msg)
        }
        this.form = {
          content: 0,
          feature: 0,
          passingRate: 0,
          evaluate: ''
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.score-pager {
  .detail {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    .inner {
      /deep/.el-rate__icon {
        font-size: 22px;
      }
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        margin-bottom: 10px;
        &.goScore {
          padding-left: 50px;
        }
      }
      .synthesize {
        .score-total {
          font-size: 50px;
          color: #327CED;
          line-height: 60px;
          .unit {
            color: #333333;
            font-size: 20px;
          }
        }
        .stars {
          margin-left: 40px;
          .tit {
            width: 170px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
            margin-bottom: 8px;
          }
          .rate {
            width: 170px;
            font-size: 24px;
          }
        }
        .star-wrap {
          margin-left: 90px;
          .star-item {
            .label {
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              line-height: 22px;
              margin-bottom: 8px;
            }
          }
        }
      }
      .form {
        margin-top: 40px;
        .section {
          .name {
            margin-bottom: 24px;
          }
          .textarea {
            height: 100px;
            border-radius: 12px;
            border-color: #999999;
            /deep/.el-textarea__inner {
              border-radius: 12px;
            }
          }
          .line {
            margin:  0 20px 0 30px;
            width: 2px;
            height: 85px;
            background: #DEDFE0;
          }
          .star-item {
            width: 50%;
            margin: 10px 0;
          }
          .label {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
            margin-right: 20px;
            width: 90px;
          }
        }
        .btns {
          margin: 30px auto;
        }
      }
      .empty {
        padding: 20px;
        text-align: center;
        color: #666;
        font-size: 14px;
      }
      .comment-item {
        border-bottom: solid 1px rgba(153, 153, 153, 0.3);
        padding: 30px 0;
        &:nth-last-child(1) {
          border-bottom: none;
        }
        .head {
          margin-bottom: 16px;
        }
        .photo {
          width: 36px;
          height: 36px;
          border-radius: 18px;
        }
        .username {
          margin-left: 10px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .label {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          margin: 0 20px 0 30px;
        }
        .comment {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          padding-left: 46px;
        }
      }
    }
  }
}
</style>
