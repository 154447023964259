<template>
  <div class="person-head">
    <Header v-if="infoform" class="flex align-c jus-b">
      <div class="img-name-wrapper flex align-c">
        <el-avatar
          shape="circle"
          :size="80"
          :src="infoform.pic ? infoform.pic : squareUrl"
        ></el-avatar>
        <div class="name-wrapper">
          <div class="name">{{ infoform.userName }}</div>
          <div>{{ infoform.school }}</div>
        </div>
      </div>
      <div class="data-wrapper flex">
        <div class="phone-wrapper">
          <div class="name">手机号</div>
          <div>{{ infoform.phone || "-" }}</div>
        </div>
        <div class="line"></div>
        <div class="phone-wrapper">
          <div class="name">参与课程数</div>
          <div>{{ infoform.courseNum }}</div>
        </div>
        <div class="line"></div>
        <div class="phone-wrapper">
          <div class="name">参与实验</div>
          <div>{{ infoform.testNum }}</div>
        </div>
        <div class="line"></div>
        <div class="phone-wrapper">
          <div class="name">收藏量</div>
          <div>{{ infoform.collectNum }}</div>
        </div>
      </div>
    </Header>
  </div>
</template>

<script>
import { MyInfo } from "@/api/ApiConfig";
export default {
  components: {},
  data() {
    return {
      infoform: null,
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
    };
  },
  watch: {},
  created() {
    this.getInfo();
  },
  mounted() {},
  methods: {
    getInfo() {
      MyInfo().then(res => {
        if (res.code !== 200) {
          return;
        }
        this.infoform = res.data;
        if (this.infoform.phone) {
          var reg = /^(\d{3})\d{4}(\d{4})$/;
          this.infoform.phone = this.infoform.phone.replace(reg, "$1****$2");
        }
        this.$emit("getInfoData", res.data);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.person-head {
  header {
    width: 970px;
    height: 160px;
    background: linear-gradient(135deg, #28b9f5, #3989eb);
    box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
    border-radius: 8px;
    padding: 40px;
    margin-bottom: 40px;
    .img-name-wrapper {
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      .name-wrapper {
        margin-left: 31px;
        .name {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 13px;
        }
      }
    }
    .data-wrapper {
      .phone-wrapper {
        text-align: center;
        width: 140px;
        .name {
          margin-bottom: 22px;
        }
      }
      .line {
        width: 1px;
        height: 60px;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(242, 249, 254, 0.8) 50%,
          rgba(255, 255, 255, 0.01) 100%
        );
      }
    }
  }
}
</style>
