<template>
  <div class="fullscreen-wrapper">
    <div class="fullscreen">
      <header class="flex align-c jus-b">
        <div class="left flex align-c">
          <span class="title">实验教学视频标题实验教学视频标题</span>
          <el-rate  v-model="rateValue" :disabled="rateValue > -1" text-color="#3989EB" :colors="['#3989EB', '#3989EB', '#3989EB']" show-score disabled-void-color="#A8CFFF" @change="handleChangeAverage()"></el-rate>
        </div>
        <div class="right flex align-c">
          <div class="study">
            <span>上次已学习到 </span>
            <span class="blue">{{ current_chapter.name }} {{ current_chapter.child_name }}，</span>
            <span class="continue" @click="handleStart()">点此继续学习</span>
          </div>
          <div class="icon flex align-c">
            <img
              v-if="data.isCollect"
              src="@/assets/images/home/icons/shou_icon_blue.png"
              @click="handleCollect('2')"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/home/icons/shou_icon2.png"
              alt=""
              @click="handleCollect('1')"
            />
            <span>{{ data.collectNum }}</span>
          </div>
          <div class="eye_icon icon flex align-c">
            <img src="@/assets/images/home/icons/eye_icon2.png" alt="" />
            <span>{{ data.viewNum }}</span>
          </div>
          <div class="avatar flex align-c">
            <el-avatar shape="circle" :size="36" :src="pic ? pic : squareUrl"></el-avatar>
            <div class="login" v-if="loginFlag" @click="unlogin">退出登录</div>
            <div class="login" v-else @click="login">请登录</div>
          </div>
        </div>
      </header>
      <div class="video-Chapter-wrapper flex">
        <div class="video-wrapper">
          <video
            v-if="fileType == 'video'"
            class="fileCont"
            disablePictureInPicture
            controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
            controls
            :src="data.videoSrc"
          />
          <el-image v-else-if="fileType == 'img'" class="fileCont" :src="data.videoSrc" fit="contain" />
          <div v-else-if="fileType == 'pdf'" class="fileCont">
            <iframe :src="data.videoSrc" frameborder="0"></iframe>
          </div>
          <div v-else-if="fileType == 'doc'" class="fileCont">
            <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + data.videoSrc" frameborder="0"></iframe>
          </div>
          <div v-else-if="fileType == 'txt'" class="fileCont">
            <iframe :src="data.videoSrc" frameborder="0"></iframe>
          </div>
          <div v-else class="fileCont">
            <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + data.videoSrc" frameborder="0"></iframe>
          </div>
        </div>
        <Chapter :data="data" @handleLearn="handleLearn" />
      </div>
    </div>
  </div>
</template>

<script>
import Chapter from "@/components/Chapter.vue";
import { MyInfo, queryStudyDetail, collectItem, myStudyScore, inStudyScore, inStudyJointLog } from "@/api/ApiConfig";
export default {
  props: {},
  components: {
    Chapter
  },
  computed: {
    fileType() {
      const suffix = this.data && this.data.videoSrc ? this.data.videoSrc.split('.')[this.data.videoSrc.split('.').length - 1] : ''
      if (suffix == 'pdf') {
        return 'pdf'
      } else if (['docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt'].includes(suffix)) {
        return 'doc'
      } else if (['txt'].includes(suffix)) {
        return 'txt'
      } else if (['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(suffix)) {
        return 'video'
      } else if (['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(suffix)) {
        return 'img'
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      rateValue: null,
      pic: "",
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      loginFlag: localStorage.getItem("pcToken") || "",
      data: {},
      current_chapter: {
        id: null,
        name: '',
        child_id: null,
        child_name: '',
        videoLink: ''
      }
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.getData();
    this.getMyStudyScore();
  },
  methods: {
    getData() {
      queryStudyDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          res.data.descs = res.data.descs ? JSON.parse(res.data.descs) : []
          res.data.videoSrc = this.$route.query.videoLink
          this.data = res.data;
          res.data.chapterList.map(item => {
            item.children.map(child => {
              if(res.data.videoSrc === child.videoLink) {
                this.current_chapter = {
                  id: item.id,
                  name: item.chapter,
                  child_id: child.id,
                  child_name: child.jointNum + ' ' + child.jointName,
                  videoLink: child.videoLink
                }
              }
            })
          })
        }
      });
    },
    getInfo() {
      MyInfo().then(res => {
        if (res.code == 200) {
          this.pic = res.data ? res.data.pic : "";
        }
      });
    },
    // 开始学习
    handleLearn(course, item) {
      this.data.videoSrc = item.videoLink;
      inStudyJointLog({
        chapterId: course.id,
        jointId: item.id,
        studyId: this.$route.query.id,
      })
    },
    // 收藏
    handleCollect(type) {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        collectItem({
          channel: "课程",
          id: this.$route.query.id
        }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg);
            return;
          }
          this.getData()
          if (type === "1") {
            this.$message.success("收藏成功");
          } else {
            this.$message.success("已取消收藏");
          }
        });
      }
    },
    unlogin() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!"
          });
          localStorage.removeItem("pcToken");
          this.$router.replace("/login");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出"
          });
        });
    },
    handleStart() {
      this.data.videoSrc = this.current_chapter.videoLink
    },
    getMyStudyScore() {
      myStudyScore({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.rateValue = res.data ? Number(res.data.divide) : null
        }
      });
    },
    // 评分
    handleChangeAverage() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      } else {
        inStudyScore({
          id: this.$route.query.id,
          divide: this.rateValue,
        }).then(() => {
          this.$message.success("评分成功")
          this.getMyStudyScore();
        })
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fullscreen-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #232932;
  font-family: Microsoft YaHei;
  font-weight: 400;
  .fullscreen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: normal;
  }
  header {
    padding: 24px 19px;
    height: 84px;
    overflow: hidden;
    background: #15181e;
    .left {
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        margin-right: 40px;
      }
    }
    .right {
      .study {
        font-size: 14px;
        color: #ffffff;
        margin-right: 59px;
        .blue {
          color: #3989eb;
        }
        .continue {
          cursor: pointer;
        }
      }
      .icon {
        img {
          width: 15px;
          height: 17px;
          margin-right: 7px;
          cursor: pointer;
        }
        span {
          font-size: 16px;
          font-family: Tensentype RuiHeiJ-W4;
          font-weight: 500;
          color: #999999;
        }
      }
      .eye_icon {
        margin-left: 59px;
        img {
          width: 19px;
          height: 13px;
        }
      }
      .avatar {
        font-size: 18px;
        color: #999999;
        margin-left: 60px;
        .login {
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .video-Chapter-wrapper {
    flex: 1;
    overflow: hidden;
  }
  .video-wrapper {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // video {
    //   width: 100%;
    //   height: 100%;
    //   background: #000;
    // }
    .fileCont {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      overflow: hidden;
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    /*音量按钮*/
    video::-webkit-media-controls-volume-control-container {
      display: none !important;
    }
    /*全屏*/
    video::-webkit-media-controls-fullscreen-button {
      display: none !important;
    }
  }
}
</style>
