<template>
  <div class="laboratory2">
    <LabHeader ref="LabHeader" @change="changePager" />
    <Pager3Home v-if="pager == 'pager3Home'" :pager="pager" :params="params" @change="changePager" />
    <Pager3Team v-if="pager == 'pager3Team'" :pager="pager" :params="params" @change="changePager" />
    <LabFooter />
  </div>
</template>
<script>
import LabHeader from "./components/header";
import LabFooter from "./components/footer";
import Pager3Home from "./home";
import Pager3Team from "./team";

export default {
  components: {
    LabHeader, 
    LabFooter,
    Pager3Home,
    Pager3Team
  },
  data() {
    return {
      pager: localStorage.getItem('pager') || 'pager3Home',
      params: JSON.parse(localStorage.getItem('pager_params')) || {}
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePager({pager, params}) {
      localStorage.setItem('pager', pager)
      localStorage.setItem('pager_params', JSON.stringify(params))
      this.pager = pager
      this.params = params
      this.$refs.LabHeader.handleChangeTab(pager === 'pager3Home' ? 0 : 1)
    }
  }
};
</script>
<style lang="scss" scoped>
.laboratory2 {
  background-color: #F4F5F8;
}
</style>
