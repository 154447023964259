<template>
  <div class="postComments">
    <BannerContent :id="this.$route.query.id" type="实验" />
    <div class="comments">
      <div class="title-wrapper flex">
        <div class="name">实验列表</div>
      </div>
      <div class="content">
        <!-- <el-collapse v-model="activeNames" >
          <el-collapse-item title="一致性 Consistency" name="1">
            <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
            <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
          </el-collapse-item>
        </el-collapse> -->
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-row-class-name="tableHeaderClassName"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="index" align="center" label="课程节数" width="180">
          </el-table-column>
          <el-table-column prop="name" align="center" label="课程名称" width="230">
          </el-table-column>
          <el-table-column prop="teacher" align="center" label="主讲老师" width="230">
          </el-table-column>
          <el-table-column prop="count" align="center" width="180" label="课时(分)">
          </el-table-column>
          <el-table-column prop="file" align="center" label="操作">
            <template slot-scope="scope">
              <span class="file" @click="handleFile(scope.row.file)">课程文件</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerContent from "@/views/Experiment/components/bannerContent";
export default {
  components: { BannerContent },
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      pageTotal: 0,
      tableData: [],
      activeNames:['1']
    };
  },
  mounted() {
    let fileData = JSON.parse(localStorage.getItem("fileData"));
    if (fileData) {
      this.tableData = fileData.videoList.map((item, index) => {
        return {
          index,
          count: 1,
          name: fileData.name,
          teacher: fileData.teacher,
          file: item
        };
      });
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "table-row";
      }
    },
    tableHeaderClassName({ row, rowIndex }) {
      return "table-header";
    },
    handleFile(file) {
      window.open(file.videoAddr);
      // const fileName = "实验文件";
      // const link = document.createElement("a");
      // link.target="_blank"
      // link.href = file.videoAddr;
      // link.download = fileName;
      // link.click(); // 模拟在按钮上实现一次鼠标点击
      // window.URL.revokeObjectURL(link.href); // 释放 URL 对象
    },
    // 分页导航
    handleSizeChange(val) {
      this.pageSize = val;
      // this.getData();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      // this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-rate__icon {
  font-size: 22px;
  margin-right: 30px;
}
/deep/ .el-textarea {
  textarea {
    resize: none;
  }
}
.postComments {
  padding-top: 100px;
  .comments {
    margin: 40px 0;
    background: #ffffff;
    padding: 29px 40px;
    box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
    border-radius: 8px;
    .title-wrapper {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 32px;
      .name {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        border-bottom: 4px solid #3989eb;
        padding-bottom: 20px;
      }
    }
    .content {
      .file {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        text-decoration: underline;
        color: #3989eb;
        cursor: pointer;
      }
      .pagination {
        margin-top: 30px;
      }
    }
  }
}
</style>
<style>
.el-table {
  width: 1080px;
}
.el-table .table-row {
  background: #f4f8fb;
}
.el-table .table-header {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #3989eb;
  text-align: center;
}
</style>
