<template>
  <div id="app">
    <router-view />
    <Dialog />
  </div>
</template>
<script>
import Dialog from "./components/Dialog.vue";
export default {
  name: "App",
  components: {
    Dialog
  },
  data() {
    return {};
  },
  mounted() {
    document.title = "辰有科技虚拟仿真实训平台";
  },
  methods: {}
};
</script>
<style lang="scss">
@import "../src/common/flex.css";
@import "../src/common/normal.scss";
@import "../src/common/reset2.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  // height: 100%;
  background: #f2f9fe;
  min-height: 100vh;
}
.box_1200 {
  width: 1200px;
}
</style>
