<template>
  <div class="team-pager">
    <div class="detail">
      <div class="inner flex flex-wrap">
        <div v-for="(item, index) in teams" :key="index" class="team-item flex flex-column jc-between ai-center">
          <img class="photo" :src="item.photo" />
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
          <el-button class="btn" size="small" type="primary" @click="handleTeamDeatil(item)">详情</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryStudyDetail } from "@/api/ApiConfig.js";

export default {
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      teams: []
    };
  },
  computed: {
  },
  mounted() {
    this.queryStudyDetail()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        const teams = [JSON.parse(res.data.teamHead), ...JSON.parse(res.data.teamUser)]
        this.teams = teams
      }
    },
    handleTeamDeatil(item) {
      this.$alert(item.desc, item.name)
    }
  }
};
</script>
<style lang="scss" scoped>
.team-pager {
  .detail {
    width: 100%;
    box-sizing: border-box;
    .inner {
      width: 100%;
      padding-top: 40px;
      .team-item {
        width: 270px;
        height: 340px;
        margin: 20px 10px;
        .photo {
          width: 180px;
          height: 190px;
          margin-top: -40px;
          background: #fff;
          border-radius: 10px;
          overflow: hidden;
        }
        .name {
          height: 26px;
          line-height: 26px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          margin: 14px 0 10px;
        }
        .desc {
          line-height: 20px;
          font-size: 14px;
          color: #666666;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          padding: 0 18px;
        }
        .btn {
          margin: 10px 0 20px;
        }
      }
    }
  }
}
</style>
