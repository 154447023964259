<template>
  <div class="openApi flex box_1200">
    API
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },

  created() {
  },
  activated() {
  },
  methods: {
  }
};
</script>
<style lang="scss">
.openApi {
  padding-top: 109px;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
