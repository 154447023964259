<template>
  <div class="postComments flex flex-column align-c">
    <HeaderContent :id="this.$route.query.id" type="课程" />
    <!-- <BannerContent :id="this.$route.query.id" type="课程" /> -->
    <div class="comments">
      <div class="title-wrapper flex">
        <div class="name">课程列表</div>
      </div>
      <div class="content">
        <el-collapse style="border: none !important;" :value="0">
          <el-collapse-item v-for="(item, index) in chapterList" :key="index" :name="index">
            <template slot="title">
              <span class="title">{{ item.chapter }}<i class="el-icon-arrow-down"></i></span>
            </template>
            <el-table
              :data="item.children"
              style="width: 100%"
              :header-row-class-name="tableHeaderClassName"
              :row-class-name="tableRowClassName"
            >
              <el-table-column prop="jointName" align="center" label="课程名称" width="230">
              </el-table-column>
              <el-table-column prop="jointNum" align="center" label="课程节数" width="180">
              </el-table-column>
              <el-table-column prop="jointTeacher" align="center" label="主讲老师" width="230">
              </el-table-column>
              <el-table-column prop="jointTime" align="center" width="180" label="课时(分)">
              </el-table-column>
              <el-table-column prop="videoLink" align="center" label="课程文件">
                <template slot-scope="scope">
                  <div v-for="(file, idx) in scope.row.file.split(',')" :key="idx" class="file" @click="handleFile(file)">{{ file.split('/')[file.split('/').length - 1] }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="videoLink" align="center" label="视频地址">
                <template slot-scope="scope">
                  <div v-for="(file, idx) in scope.row.videoLink.split(',')" :key="idx" class="file" @click="handleFile(file)">{{ file.split('/')[file.split('/').length - 1] }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>

        <!-- <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { queryStudyDetail } from "@/api/ApiConfig";
import HeaderContent from "@/views/Experiment/components/headerContent";
// import BannerContent from "@/views/Experiment/components/bannerContent";
export default {
  components: { HeaderContent },
  data() {
    return {
      pageIndex: 1,
      pageTotal: 0,
      chapterList: [],
      tableData: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      queryStudyDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.chapterList = res.data.chapterList || [];
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "table-row";
      }
    },
    tableHeaderClassName({ row, rowIndex }) {
      return "table-header";
    },
    handleFile(row) {
      window.open(row)
    },
    // 分页导航
    handleSizeChange(val) {
      this.pageSize = val;
      // this.getData();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      // this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-rate__icon {
  font-size: 22px;
  margin-right: 30px;
}
/deep/ .el-textarea {
  textarea {
    resize: none;
  }
}
.postComments {
  width: 100%;
  padding-top: 100px;
  .comments {
    width: 1200px;
    margin: 40px 0;
    background: #ffffff;
    padding: 29px 40px;
    box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
    border-radius: 8px;
    .title-wrapper {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 32px;
      .name {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        border-bottom: 4px solid #3989eb;
        padding-bottom: 20px;
      }
    }
    .content {
      .title {
        font-size: 20px;
        color: #333e55;

        &::before {
          content: "";
          display: inline-block;
          width: 3px;
          height: 14px;
          background: #333e55;
          margin-right: 8px;
        }
      }
      .file {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        text-decoration: underline;
        color: #3989eb;
        cursor: pointer;
      }
      .pagination {
        margin-top: 30px;
      }
    }
  }
}
</style>
<style>
.el-table {
  width: 1080px;
}
.el-table .table-row {
  background: #f4f8fb;
}
.el-table .table-header {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #3989eb;
  text-align: center;
}
.el-collapse {
  border: none !important;
}
</style>
