<template>
  <div class="headerBox">
    <div class="boxFlex">
      <div class="left">
        <div class="logo">
          <img class="img1" src="@/assets/images/home/login.png" alt="" />
        </div>
        <div class="title">
          <el-menu
            v-for="(item, idx) of list"
            :key="idx"
            :default-active="activeIndex"
            active-text-color="#3989EB"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item :index="String(idx + 1)">{{ item.moduleName }}</el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="right flex align-c">
        <div class="s_box">
          <div class="down_box">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ curValue }}<i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in downList" :key="index" :command="index">{{
                  item
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="input flex">
            <el-input
              suffix-icon="el-icon-s"
              v-model="inputValue"
              placeholder="请输入搜索内容"
              clearable
            ></el-input>
            <div class="search-wrapper" @click="searchs">
              <img :src="searchIcon" alt="" />
            </div>
          </div>
        </div>
        <el-avatar shape="circle" :size="36" :src="pic ? pic : squareUrl"></el-avatar>
        <div class="login" v-if="loginFlag" @click="unlogin">退出登录</div>
        <div class="login" v-else @click="login">请登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { MyInfo } from "@/api/ApiConfig";
export default {
  components: {},
  props: {},
  watch: {
    $route(to) {
      this.changeTabIdx(to.path)
    }
  },
  data() {
    return {
      pic: "",
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      searchIcon: require("@/assets/images/icon/headSearch.png"),
      loginFlag: localStorage.getItem("pcToken") || "",
      activeIndex: "1",
      curValue: "新闻",
      inputValue: "",
      curPath: "",
      downList: ["新闻", "通知", "实验", "课程"],
      list: [
        {
          moduleName: "首页"
        },
        {
          moduleName: "新闻"
        },
        {
          moduleName: "实验"
        },
        {
          moduleName: "课程"
        },
        {
          moduleName: "资料文件"
        },
        {
          moduleName: "题库"
        },
        {
          moduleName: "API"
        },
        {
          moduleName: "个人中心"
        }
      ],
      selIndex: 0
    };
  },
  created() {
    this.getInfo();
    this.getMenu();
  },
  mounted() {
    this.changeTabIdx(this.$route.path)
  },
  methods: {
    changeTabIdx(curPath) {
      this.curPath = curPath;
      if (curPath == "/") {
        this.activeIndex = "1";
      } else if (curPath == "/news" || curPath.indexOf("news") != -1) {
        this.activeIndex = "2";
      } else if (curPath == "/experiment" || curPath.indexOf("experiment") != -1) {
        this.activeIndex = "3";
      } else if (curPath == "/course" || curPath.indexOf("course") != -1) {
        this.activeIndex = "4";
      } else if (curPath == "/resource" || curPath.indexOf("resource") != -1) {
        this.activeIndex = "5";
      } else if (curPath == "/questions" || curPath.indexOf("questions") != -1) {
        this.activeIndex = "6";
      } else if (curPath == "/openApi" || curPath.indexOf("openApi") != -1) {
        this.activeIndex = "7";
      } else if (curPath == "/personalCenter" || curPath.indexOf("personalCenter") != -1) {
        this.activeIndex = "8";
      }
    },
    getInfo() {
      MyInfo().then(res => {
        if (res.code == 200) {
          this.pic = res.data ? res.data.pic : "";
        }
      });
    },
    handleCommand(command) {
      if (command === 0) {
        this.curValue = "新闻";
      } else if (command === 1) {
        this.curValue = "通知";
      } else if (command === 2) {
        this.curValue = "实验";
      } else if (command === 3) {
        this.curValue = "课程";
      }
    },
    searchs() {
      if (this.curValue == "新闻") {
        var path = "/news";
      } else if (this.curValue == "通知") {
        var path = "/news/notifyMessage";
      } else if (this.curValue == "实验") {
        var path = "/experiment";
      } else if (this.curValue == "课程") {
        var path = "/course";
      }
      this.$router.push({
        path,
        query: {
          inputValue: this.inputValue
        }
      });
    },
    getMenu() {
      //   this.$request({
      //     url: GETALLBUSINESS,
      //     baseURL: BASEURL,
      //     method: "post",
      //     params: { token: "" }
      //   }).then(res => {
      //     console.log(res);
      //     // this.list = res.data;
      //   });
    },
    selectItem(item, index) {
      // this.activeIndex = String(index)
      this.$router.push({
        path: "",
        query: {
          id: ""
        }
      });
    },
    handleSelect(key, keyPath) {
      this.activeIndex = String(keyPath);
      if (this.activeIndex == 1) {
        var path = "/";
      } else if (this.activeIndex == 2) {
        var path = "/news";
      } else if (this.activeIndex == 3) {
        var path = "/experiment";
      } else if (this.activeIndex == 4) {
        var path = "/course";
      } else if (this.activeIndex == 5) {
        var path = "/resource";
      } else if (this.activeIndex == 6) {
        var path = "/questions";
      } else if (this.activeIndex == 7) {
        var path = "/openApi";
      } else if (this.activeIndex == 8) {
        var path = "/personalCenter";
      } else {
        var path = "/";
      }
      this.$router.push({
        path
      });
    },
    login() {
      this.$router.push("/login");
    },
    select(index) {
      this.selIndex = index;
    },
    unlogin() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!"
          });
          localStorage.removeItem("pcToken");
          this.$router.replace("/login");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出"
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.boxFlex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .left,
  .right {
    display: flex;
  }
}
.box_1200 {
  width: 1200px;
  height: 100%;
}
.headerBox {
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 2px 25px 0px rgba(0, 86, 163, 0.15);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .logo {
    display: flex;
    align-items: center;
    height: 51px;
    .img1 {
      width: 334px;
      height: 51px;
    }
  }

  .title {
    display: flex;
    margin-left: 60px;
    div.item {
      margin-right: 50px;
      font-size: 16px;
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      color: #555555;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .el-menu.el-menu--horizontal {
      border: none;
    }
  }
  .s_box {
    width: 240px;
    height: 32px;
    line-height: 32px;
    background: #ffffff;
    border: 1px solid #ACB3C5;
    border-radius: 19px;
    display: flex;
    margin-right: 41px;
    .down_box {
      text-align: center;
      width: 78px;
      background: #ffffff;
      border-radius: 15px 0 0 15px;
      padding: 0 5px;
      cursor: pointer;
    }
    .input {
      /deep/.el-input {
        input {
          border-radius: 15px;
          height: 30px;
          border: none;
          outline: none;
          background: none;
        }
      }
      .search-wrapper {
        line-height: 30px;
        cursor: pointer;
        padding-right: 10px;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }
    }
  }
  .login {
    margin-left: 12px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.headerBox {
  .el-menu--horizontal > .el-menu-item,
  .el-menu--horizontal .el-menu .el-menu-item,
  .el-menu--horizontal .el-submenu .el-submenu__title {
    color: #666;
    font-weight: bold;
    border: none !important;
  }
  .el-menu.el-menu--horizontal {
    border: none !important;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border: none !important;
  }
}
</style>
