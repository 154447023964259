<template>
  <div>
    <Pager3 v-if="template_name === 'template3'" />
    <Pager2 v-else-if="template_name === 'template2'" />
    <Pager1 v-else />
  </div>
</template>
<script>
import Pager1 from "./pager1/index";
import Pager2 from "./pager2/index";
import Pager3 from "./pager3/index";
export default {
  components: {
    Pager1,
    Pager2,
    Pager3
  },
  data() {
    return {
      template_name: localStorage.getItem('template')
    }
  }
};
</script>
