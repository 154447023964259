<template>
  <div class="myProfile flex">
    <PersonMenu />
    <div class="PersonalCenter-container">
      <Head @getInfoData="getInfoData" ref="head" />
      <div class="PortraitOne">
        <article>
          <div class="title-wrapper flex">
            <div class="name">账户设置</div>
          </div>
          <div class="form_box">
            <div>
              <span class="lable">登录密码</span>
              <el-button
                class="confirm"
                size="mini"
                type="primary"
                @click="() => (dialogShow = true)"
                >修改</el-button
              >
              <div class="tips">
                <i class="el-icon-warning"></i
                >建议您定期更换密码，设置安全性高的密码可以使帐号更安全
              </div>
            </div>
            <div v-if="!infoData.email">
              <span class="lable">邮箱绑定</span>
              <el-button
                class="confirm"
                size="mini"
                type="primary"
                @click="() => (centerDialogVisible = true)"
                >绑定</el-button
              >
              <div class="tips" v-if="!infoData.email">
                <div>当前还未绑定邮箱地址</div>
                <i class="el-icon-warning"></i>绑定邮箱可以用于登录帐号，重置密码或其他安全验证
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <!-- 绑定邮箱 弹窗 -->
    <el-dialog
      title="邮箱绑定"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      @close="closeDilog('ruleFormPassword')"
    >
      <el-form :model="ruleFormPassword" :rules="rulesPassword" ref="ruleFormPassword">
        <el-form-item prop="email">
          <el-input v-model="ruleFormPassword.email" placeholder="请输入邮箱账号"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="flex">
            <el-input
              v-model="ruleFormPassword.code"
              autocomplete="off"
              placeholder="请输入验证码"
              clearable
            ></el-input>
            <el-button class="codeBtn" :disabled="isDisabled" @click="getCode">{{
              sendName
            }}</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          class="title"
          @click="confirmBind('ruleFormPassword')"
          >绑定</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改密码 弹窗 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogShow"
      @close="closeDilog('resetPwdForm')"
      width="38%"
      center
    >
      <el-form label-width="120px" :model="resetPwdForm" :rules="rules" ref="resetPwdForm">
        <el-form-item label="当前密码：" prop="oldPwds">
          <el-input
            v-model="resetPwdForm.oldPwds"
            auto-complete="off"
            placeholder="请输入当前密码"
            show-password
            :maxlength="8"
            onKeyUp="value=value.replace(/[\W]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPwds">
          <el-input
            v-model="resetPwdForm.newPwds"
            auto-complete="off"
            placeholder="请输入新密码"
            show-password
            maxlength="8"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码：" prop="sureNewPwds">
          <el-input
            v-model="resetPwdForm.sureNewPwds"
            auto-complete="off"
            placeholder="请再次输入新密码"
            show-password
            maxlength="8"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" class="title" @click="submitForm('resetPwdForm')"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sendEmailCode, BindEmail, UpPwd } from "@/api/ApiConfig";
import PersonMenu from "@/views/PersonalCenter/components/menu";
import Head from "@/views/PersonalCenter/components/head";
import md5 from "js-md5";
export default {
  components: { PersonMenu, Head },
  data() {
    var validateNumChar = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户密码"));
      } else {
        var len = /^.{8}$/;
        var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,9}$/;
        var _false = false;
        if (!len.test(value)) {
          _false = true;
        }
        if (!reg.test(value)) {
          _false = true;
        }
        if (_false) {
          callback(new Error("请输入8位字母数字组合!"));
        } else {
          callback();
        }
      }
    };
    return {
      infoData: {
        email: ""
      },
      //绑定邮箱
      centerDialogVisible: false,
      ruleFormPassword: {
        email: "",
        code: ""
      },
      rulesPassword: {
        email: [
          { required: true, message: "请输入邮箱账号", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱账号", trigger: ["blur", "change"] }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
      sendName: "获取验证码",
      isDisabled: false,
      timer: null,
      second: 60,

      //重置密码
      dialogShow: false,
      form: {
        oldPwd: "", //原MD5加密
        newPwd: "" //新MD5加密
      },
      resetPwdForm: {
        oldPwds: "",
        newPwds: "",
        sureNewPwds: ""
      },
      // rules表单验证
      rules: {
        oldPwds: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
          { validator: validateNumChar, trigger: "blur" }
        ],
        newPwds: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validateNumChar, trigger: "blur" }
        ],
        sureNewPwds: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { validator: validateNumChar, trigger: "blur" }
        ]
      }
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getInfoData(val) {
      this.infoData = val;
    },
    validateNumChar(rule, value, callback) {
      if (value === "") {
        this.$message.error("请输入登录密码!");
        return false;
      } else {
        var len = /^.{8}$/;
        var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,9}$/;
        var _false = false;
        if (!len.test(value)) {
          _false = true;
        }
        if (!reg.test(value)) {
          _false = true;
        }
        if (_false) {
          this.$message.error("请输入8位字母数字组合!");
        } else {
          return true;
        }
      }
    },
    closeDilog(formName) {
      this.$refs[formName].resetFields(); //重置表单数据
    },
    getCode() {
      if (!this.ruleFormPassword.email) {
        this.$message.warning("请先输入邮箱！");
        return;
      }
      const reg1 = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!reg1.test(this.ruleFormPassword.email)) {
        this.$message.warning("请填写正确的邮箱格式！");
        return;
      }
      this.openLoading();
      sendEmailCode({
        email: this.ruleFormPassword.email
      }).then(res => {
        this.openLoading().close();
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.timer = setInterval(() => {
          this.second--;
          this.sendName = this.second + "秒后重发";
          this.isDisabled = true;
          if (this.second < 0) {
            this.sendName = "获取验证码";
            this.isDisabled = false;
            this.second = 60;
            clearInterval(this.timer);
          }
        }, 1000);
        this.$message.success("验证码发送成功");
      });
    },
    confirmBind(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.openLoading();
          BindEmail({
            code: this.ruleFormPassword.code,
            email: this.ruleFormPassword.email
          }).then(res => {
            this.openLoading().close();
            if (res.code !== 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success("邮箱绑定成功");
            this.centerDialogVisible = false;
            this.$refs[formName].resetFields(); //重置表单数据
            this.$refs.head.getInfo();
          });
        } else {
          return false;
        }
      });
    },
    // 编辑、增加页面保存方法
    submitForm(formName) {
      this.form.oldPwd = md5(this.resetPwdForm.oldPwds).toUpperCase();
      this.form.newPwd = md5(this.resetPwdForm.newPwds).toUpperCase();
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.resetPwdForm.newPwds != this.resetPwdForm.sureNewPwds) {
            this.$message.warning("新密码不一致");
            return;
          }
          this.openLoading();
          UpPwd(this.form).then(res => {
            this.openLoading().close();
            if (res.code !== 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success("密码修改成功请重新登录！");
            setTimeout(() => {
              localStorage.removeItem("pcToken");
              this.$router.replace("/");
            }, 1500);
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-form-item__content {
  .el-input__inner {
    padding: 0 102px 0 15px;
  }
  .el-input__suffix {
    right: 100px;
  }
}
.tips {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fe5555;
  padding-bottom: 61px;
  text-align: left;
  // padding-left: 90px;
  padding-top: 30px;
}
.myProfile {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 109px;
  ul {
    list-style-type: none;
  }
  .PersonalCenter-container {
    width: 970px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    .form-wrapper {
      padding: 29px 40px 40px;
      margin-bottom: 40px;
      background: #ffffff;
      border-radius: 8px;
      .title-wrapper {
        border-bottom: 1px solid #e5e5e5;
        .name {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333e55;
          border-bottom: 4px solid #3989eb;
          padding-bottom: 20px;
          margin-right: 18px;
        }
        .num {
          font-size: 16px;
          font-weight: 400;
          color: #3989eb;
          line-height: 40px;
        }
      }
    }
  }
  .PortraitOne {
    article {
      padding: 29px 40px 40px;
      margin-bottom: 40px;
      background: #ffffff;
      border-radius: 8px;
      .title-wrapper {
        border-bottom: 1px solid #e5e5e5;
        .name {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333e55;
          border-bottom: 4px solid #3989eb;
          padding-bottom: 20px;
          margin-right: 18px;
        }
        .num {
          font-size: 16px;
          font-weight: 400;
          color: #3989eb;
          line-height: 40px;
        }
      }

      .form_box {
        width: 600px;
        margin-top: 40px;
        margin-left: 100px;
        color: #000000;
        .lable {
          margin-right: 30px;
        }
        /deep/.el-form-item {
          margin-bottom: 40px;
        }

        /deep/.el-form-item__content {
          position: relative;
          .el-input {
            width: 500px;
            height: 42px;
          }
          button {
            position: absolute;
            bottom: 6px;
            right: 0;
          }
        }
        .confirm {
          width: 68px;
          height: 30px;
          background: #3989eb;
          border-radius: 4px;
          margin-right: 30px;
        }
      }
    }
  }
}
</style>
