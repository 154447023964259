<template>
  <div class="Experiment_detail flex flex-column align-c">
    <BannerContent :id="this.$route.query.id" type="实验" />
    <div class="center flex jus-b box_1200">
      <div class="left">
        <div class="flex jus-a">
          <div class="count">
            <p>总评分</p>
            <p>{{ this.$route.query.score }}</p>
          </div>
          <div class="data flex jus-c align-a">
            <p class="flex flex-column jus-a align-c">
              <span>实验人数</span>
              <span>6</span>
            </p>
            <p class="flex flex-column jus-a align-c">
              <span>实验平均用时</span>
              <span>6</span>
            </p>
            <p class="flex flex-column jus-a align-c">
              <span>实验完成率</span>
              <span>60%</span>
            </p>
          </div>
        </div>
        <div class="chart" id="chart"></div>
      </div>
      <div class="right">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="实验介绍" name="first">
            <div class="contentBox">
              <el-tabs
                class="secondTab"
                v-model="activeName1"
                @tab-click="handleClick1"
              >
                <el-tab-pane label="实验教学目标" name="target">
                  <div class="content ql-editor" v-html="data.teachingTarget"></div>
                </el-tab-pane>
                <el-tab-pane label="实验原理" name="principle">
                  <div class="content ql-editor" v-html="data.studyPrinciple"></div>
                </el-tab-pane>
                <el-tab-pane label="实验教学过程与实验方法" name="method">
                  <div class="content ql-editor" v-html="data.processMethod"></div>
                </el-tab-pane>
                <el-tab-pane label="实验步骤" name="step">
                  <div class="content ql-editor" v-html="data.studyStep"></div>
                </el-tab-pane>
                <el-tab-pane label="实验结果与结论" name="result">
                  <div class="content ql-editor" v-html="data.result"></div>
                </el-tab-pane>
              </el-tabs>
              <!-- <div class="content ql-editor" v-html="data.content"></div> -->
            </div>
          </el-tab-pane>
          <el-tab-pane label="实验必读" name="second">
            <div class="bidu-box">
              <div class="bidu">
                <h4>硬件要求</h4>
                <p v-html="data.hardwareAsk"></p>
              </div>
              <div class="bidu">
                <h4>网络要求</h4>
                <p v-html="data.interAsk"></p>
              </div>
              <div class="bidu">
                <h4>用户操作系统要求</h4>
                <p v-html="data.systemAsk"></p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="实验文件" name="third">
            <div class="contentBox downloadFile">
              <div
                v-for="(item, index) in data.videoList"
                :key="index"
                class="downfile"
                @click="handleFile(item)"
              >
                <img src="@/assets/images/icon/down_icon.png" alt="" />{{
                  item.videoAddr
                    ? item.videoAddr.split("/")[item.videoAddr.split("/").length - 1]
                    : ""
                }}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="down">
      <div class="down_inner box_1200 flex">
        <div class="left flex flex-column jus-c">
          <p class="title">实验系统浏览器要求</p>
          <div class="content flex">
            <p class="flex flex-column align-c">
              <img src="@/assets/images/icon/firefox_icon.png" alt="" />
              <span>火狐浏览器</span>
            </p>
            <p class="flex flex-column align-c">
              <img src="@/assets/images/icon/chrome_icon.png" alt="" />
              <span>谷歌浏览器</span>
            </p>
            <p class="flex flex-column align-c">
              <img src="@/assets/images/icon/chrome_icon.png" alt="" />
              <span>Microsoft Edge</span>
            </p>
          </div>
        </div>
        <div class="right flex align-c">
          <div class="right1">
            <p class="title">团队成员</p>
            <div class="content">
              <div class="person">
                负责人：<span>{{ data.teamHead }}</span>
              </div>
              <div class="text">
                <ul class="team">
                  <li v-for="(item,index) in data.teamNumberList" :key="index">{{ item }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right2">
            <p class="title">实验热线</p>
            <div class="content">
              0317-4685123
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryStudyDetail, showRecord } from "@/api/ApiConfig";
import BannerContent from "@/views/Experiment/components/bannerContent";
import * as echarts from "echarts";
export default {
  components: { BannerContent },
  data() {
    return {
      activeName: "first",
      activeName1: "target",
      data: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleClick(val) {},
    handleClick1(val) {},
    getData() {
      queryStudyDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.data = res.data;
          this.data.teamNumberList = res.data.teamUser.split(',')
          this.initMap();
        }
      });
      showRecord({
        channel: '实验',
        id: this.$route.query.id
      })
    },
    handleFile(item) {
      let data = {
        teacher: this.data.teacher,
        name: this.data.name,
        videoList: this.data.videoList
      };
      localStorage.setItem("fileData", JSON.stringify(data));
      window.open(item.videoAddr);
    },
    initMap() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("chart"));
      var datas = [ //优秀、普通、及格、不及格
        // { value: Number(this.data.viewNum), name: "访问量" },
        // { value: Number(this.data.collectNum), name: "收藏" }
        { value: 25, name: "优秀" },
        { value: 25, name: "普通" },
        { value: 25, name: "及格" },
        { value: 25, name: "不及格" }

      ];
      // 绘制图表
      myChart.setOption({
        color: ["#2366C9", "#468DF6", "#3DB4F0", "#8CD8FF"],
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          left: "0",
          top: "middle",
          icon: "circle",
          data: datas.map(item => item.name),
          formatter: function(name) {
            let total = 0;
            let tarValue;
            for (let i = 0; i < datas.length; i++) {
              total += datas[i].value;
              if (datas[i].name == name) {
                tarValue = datas[i].value;
              }
            }
            let v = tarValue + "人";
            //计算出百分比
            let p = Math.round((tarValue / total) * 100) + "%";
            return `${name} ${p}`;
            //name是名称，v是数值
          }
        },
        series: [
          {
            name: "",
            type: "pie",
            left: "150",
            // radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: datas
          }
        ]
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.box_1200 {
  width: 1200px;
  height: 100%;
}
.Experiment_detail {
  width: 100%;
  padding-top: 110px;
  .center {
    min-height: 440px;
    margin-top: 30px;
    margin-bottom: 30px;
    .left,
    .right {
      width: 585px;
      min-height: 440px;
      background: #ffffff;
      box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
      border-radius: 8px;
      padding: 30px;
      box-sizing: border-box;
    }
    .left {
      .count {
        line-height: 44px;
        p {
          color: #3989eb;
          font-weight: bold;
        }
        p:nth-child(1) {
          font-size: 16px;
        }
        p:nth-child(2) {
          font-size: 36px;
        }
      }
      .data {
        width: 423px;
        height: 92px;
        background: linear-gradient(90deg, #38a8f6, #767cfc);
        opacity: 0.88;
        border-radius: 4px;
        color: #fff;
        p {
          flex: 1;
          height: 100%;
          position: relative;
          &:after {
            display: inline-block;
            content: "";
            width: 1px;
            height: 36px;
            background: #fff;
            position: absolute;
            right: -1px;
          }
          span {
            font-weight: bold;
          }
          span:nth-child(1) {
            font-size: 14px;
          }
          span:nth-child(2) {
            font-size: 24px;
          }
        }
      }
      .chart {
        width: 80%;
        height: 260px;
        margin-top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .right {
      /deep/ .el-tabs__item {
        font-size: 16px;
        font-weight: bold;
      }
      .secondTab {
        /deep/ .el-tabs__item {
          font-size: 12px;
          font-weight: normal;
        }
        /deep/ .el-tabs__header {
          border: none;
        }
      }
      /deep/.ql-editor {
        img {
          max-width: 100%;
        }
      }
      .bidu-box {
        height: 320px;
        overflow-y: scroll;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .bidu {
        margin: 10px 0;
      }
      ::-webkit-scrollbar {
        width: 2px;
        background: transparent;
      }
      .contentBox {
        height: 326px;
        position: relative;
      }
      .content {
        height: 271px;
        overflow-y: scroll;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .downloadFile {
        overflow: auto;
      }
      .downfile {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        text-decoration: underline;
        color: #3989eb;
        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .down {
    width: 100%;
    min-height: 160px;
    background: #fff;
    padding: 20px 0;
    .down_inner {
      margin: 0 auto;
    }
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .content {
      p {
        img {
          margin-bottom: 10px;
        }
      }
    }
    .left {
      flex: 1;
      position: relative;
      &:after {
        display: inline-block;
        content: "";
        width: 1px;
        height: 36px;
        background: #3989eb;
        position: absolute;
        right: -1px;
      }
      .content {
        margin-top: 20px;
        p {
          margin-right: 40px;
          img {
            width: 38px;
            height: 38px;
          }
          span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
    .right {
      flex: 2;
      padding-left: 64px;
      .content {
        margin-top: 20px;
        font-size: 14px;
        .person {
          font-weight: bold;
          color: #666666;
          margin-bottom: 15px;
          span {
            color: #3989eb;
          }
        }
        .text {
          color: #666666;
          ul {
            margin-right: 30px;
            list-style-type: disc;
          }
        }
      }
      .right1 {
        flex: 1;
      }
      .right2 {
        color: #3989eb;
        .content {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #468fec;
        }
      }
      .team {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 190px;
          margin: 0 10px;
        }
      }
    }
  }
}
</style>
