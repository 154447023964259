import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import Index from "@/views/Index.vue";
import Home from "@/views/Home.vue";
import PersonalCenter from "@/views/PersonalCenter/index.vue";
import MyProfile from "@/views/PersonalCenter/myProfile.vue";
import AccountSettings from "@/views/PersonalCenter/accountSettings.vue";
import BrowsingHistory from "@/views/PersonalCenter/browsingHistory.vue";
import ParticipateExperiments from "@/views/PersonalCenter/participateExperiments.vue";
import LearningCourses from "@/views/PersonalCenter/learningCourses.vue";
import News from "@/views/News/index.vue";
import NotifyMessage from "@/views/News/notifyMessage.vue";
import NewsDetail from "@/views/News/newsDetail.vue";
import Experiment from "@/views/Experiment/index.vue";
import ExperimentDetail from "@/views/Experiment/detail.vue";
import PostComments from "@/views/Course/postComments.vue";
import Classes from "@/views/Course/Classes.vue";
import ExperList from "@/views/Experiment/Experiment.vue";
import Course from "@/views/Course/index.vue";
import CourseDetail from "@/views/Course/detail.vue";
import Resource from "@/views/Resource/index.vue";
import Questions from "@/views/Questions/index.vue";
import OpenApi from "@/views/OpenApi/index.vue";
import Fullscreen from "@/views/Course/fullscreen";
import Laboratory from "@/views/Laboratory/index";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/course/fullscreen",
    name: "视频全屏",
    component: Fullscreen,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/laboratory",
    name: "实验",
    component: Laboratory,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "/",
        name: "首页",
        component: Home,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/personalCenter",
        name: "我的收藏",
        component: PersonalCenter,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/personalCenter/browsingHistory",
        name: "浏览记录",
        component: BrowsingHistory,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/personalCenter/participateExperiments",
        name: "参与实验",
        component: ParticipateExperiments,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/personalCenter/learningCourses",
        name: "学习课程",
        component: LearningCourses,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/personalCenter/myProfile",
        name: "我的资料",
        component: MyProfile,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/personalCenter/accountSettings",
        name: "账户设置",
        component: AccountSettings,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/news",
        name: "新闻",
        component: News,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/newsDetail",
        name: "详情",
        component: NewsDetail,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/news/notifyMessage",
        name: "通知",
        component: NotifyMessage,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/experiment",
        name: "实验",
        component: Experiment,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/experiment/Detail",
        name: "实验详情",
        component: ExperimentDetail,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/experiment/ExperList",
        name: "课程列表",
        component: ExperList,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/course/postComments",
        name: "发表评价",
        component: PostComments,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/course/Classes",
        name: "我要上课",
        component: Classes,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/course",
        name: "课程",
        component: Course,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/course/Detail",
        name: "课程详情",
        component: CourseDetail,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/resource",
        name: "资料文件",
        component: Resource,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/questions",
        name: "题库",
        component: Questions,
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/openApi",
        name: "API",
        component: OpenApi,
        meta: {
          requireAuth: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
