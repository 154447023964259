<template>
  <div class="PersonalCenter flex">
    <PersonMenu />
    <div class="PersonalCenter-container">
      <Head />
      <PortraitOne
        :hideHeader="true"
        type="course"
        :pageTotal="queryParams.pageTotal"
        :listData="listData"
        @getPageSize="getPageSize"
        @getPageIndex="getPageIndex"
      />
    </div>
  </div>
</template>

<script>
import { studyModuleList } from "@/api/ApiConfig.js";

import Head from "@/views/PersonalCenter/components/head";
import PersonMenu from "@/views/PersonalCenter/components/menu";
// import HorizontalImgText from "@/components/HorizontalImgText";
// import PortraitMore from "@/components/PortraitMore";
import PortraitOne from "@/components/PortraitOne";
export default {
  components: {
    Head,
    PersonMenu,
    // HorizontalImgText
    // PortraitMore,
    PortraitOne
  },
  data() {
    return {
      queryParams: {
        channel: "课程",
        pageIndex: 1,
        pageSize: 10,
        type: ""
      },
      pageTotal: 0,
      listData: [],
      ruleCodes: [
        { label: "我的收藏", value: "1" },
        { label: "浏览记录", value: "2" },
        { label: "参与实验", value: "3" },
        { label: "学习课程", value: "4" },
        { label: "我的资料", value: "5" },
        { label: "账户设置", value: "6" }
      ],
      number: "1"
    };
  },
  created() {
    this.getStudyModuleList();
  },
  methods: {
    getPageIndex(val) {
      this.queryParams.pageIndex = val;
      this.getStudyModuleList();
    },
    getPageSize(val) {
      this.queryParams.pageSize = val;
      this.getStudyModuleList();
    },
    getStudyModuleList() {
      studyModuleList(this.queryParams).then(res => {
        if (res.code === 200) {
          let listData = res.data.list;
          listData.map(item => {
            this.$set(
              item,
              "filterContent",
              item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
            );
          });
          this.listData = listData;
          this.pageTotal = res.data.total;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.PersonalCenter {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 109px;
  ul {
    list-style-type: none;
  }
  .menu-wrapper {
    padding: 37px 0;
    margin-right: 50px;
    position: relative;
    li {
      margin-bottom: 25px;
      cursor: pointer;
      line-height: 44px;
      img {
        width: 15px;
        height: 15px;
        vertical-align: middle;
        margin: 0 13px 0 30px;
      }
    }
    .blue {
      width: 180px;
      height: 44px;
      background: linear-gradient(135deg, #28b9f5, #3989eb);
      border-radius: 4px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 44px;
    }
  }
  .PersonalCenter-container {
    width: 970px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    header {
      width: 970px;
      height: 160px;
      background: linear-gradient(135deg, #28b9f5, #3989eb);
      box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
      border-radius: 8px;
      padding: 40px;
      margin-bottom: 40px;
      .img-name-wrapper {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        .name-wrapper {
          margin-left: 31px;
          .name {
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 13px;
          }
        }
      }
      .data-wrapper {
        .phone-wrapper {
          text-align: center;
          width: 140px;
          .name {
            margin-bottom: 22px;
          }
        }
        .line {
          width: 1px;
          height: 60px;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(242, 249, 254, 0.8) 50%,
            rgba(255, 255, 255, 0.01) 100%
          );
        }
      }
    }
  }
  /deep/.PortraitOne article {
    padding: 29px 40px 40px;
    margin-bottom: 40px;
    background: #ffffff;
    border-radius: 8px;
    .recommend_list {
      padding: 39px 0 0 0;
    }
  }
}
</style>
