<template>
  <div class="commen-box">
    <div class="eva_up flex jus-b align-c">
      <div class="info flex align-c">
        <img :src="data.pic" alt="" />
        <span>{{ data.userName }}</span>
      </div>
      <div class="ava_time">{{ data.createTime }}</div>
    </div>
    <div class="eva_down">
      <span>
        {{ data.content }}
        <span class="replay" @click="handleReply">回复</span>
      </span>
      <div class="comments_content" v-if="showReply">
        <div class="commen_text">
          <el-input type="textarea" placeholder="请输入评价内容" v-model="form.evaluate"> </el-input>
        </div>
        <div class="comments_btn" @click="handleSubmit">回复</div>
      </div>
    </div>
    <div class="children">
      <div v-for="(item, index) in data.children" :key="index" class="child">
        <div class="eva_up flex jus-b align-c">
          <div class="info flex align-c">
            <img :src="item.pic" alt="" />
            <span>{{ item.userName }}</span>
          </div>
          <div class="ava_time">{{ item.createTime }}</div>
        </div>
        <div class="eva_down">
          <span>
            {{ item.content }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{
        evaluate:''
      },
      showReply:false
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleReply(){
      this.showReply = !this.showReply
    },
    handleSubmit(){
      this.$emit('submit', {
        content: this.form.evaluate,
        id: this.$route.query.id,
        parentId: this.data.id,
        toId: this.data.id
      })
      this.form.evaluate = "";
    }
  }
};
</script>
<style lang="scss">
.commen-box {
  .eva_up {
    height: 38px;
    .info {
      img {
        width: 30px;
        height: 30px;
        background: #8e8e8e;
        border-radius: 50%;
        margin-right: 11px;
      }
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #3989eb;
      }
    }
    .ava_time {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
  .eva_down {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;
    margin-left: 47px;

    .replay {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #3989eb;
      margin-right: 10px;
      cursor: pointer;
    }
    .comments_content {
      width: 100%;
      height: 75px;
      background: #ffffff;
      border-radius: 0px 0px 8px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      .commen_text {
        width: 545px;
        .el-input {
          height: 56px;
          .el-input__inner {
            height: 56px;
          }
        }
      }
      .comments_btn {
        width: 66px;
        height: 56px;
        line-height: 56px;
        color: #fff;
        background: #3989eb;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .children {
    margin: 10px 0 10px 41px;
    .child {
      margin: 10px 0;
    }
    .eva_down {
      margin-top: 10px;
    }
  }
}
</style>
