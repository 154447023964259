import axios from "axios";
/**
 * 获取localSorage对应字段
 * @param {String} properName
 */
const getValue = properName => localStorage.getItem(properName);

const service = axios.create({
  timeout: 60 * 1000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
});

service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (!error.response) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default service;
