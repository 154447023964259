<!-- 横版 左边图片右边文字描述 -->
<template>
  <div class="HorizontalImgText">
    <article>
      <SearchSelect v-if="!hideHeader" :from_type="type" @change="handleChangeSearch"></SearchSelect>
      <div v-else class="title-wrapper flex">
        <div class="name">实验</div>
        <div class="num" v-if="isShowCollect">收藏共{{ pageTotal }}条</div>
      </div>
      <ul v-if="list2.length > 0" class="recommend_list">
        <li class="flex" v-for="(item, index) in list2" :key="index">
          <img :src="item.pic" @click="handleClick(item)" alt="" class="banner" />
          <div class="article-content">
            <div @click="handleClick(item)">
              <div class="article-title" v-if="item.title">{{ item.title }}</div>
              <div class="article-title" v-else>{{ item.name }}</div>
              <div class="articles ellipsis-multi-line">
                {{ ToText(item.filterContent) }}
              </div>
            </div>
            <div class="info flex jus-b align-c">
              <div class="read-wrapper flex">
                <div class="read1">
                  <img
                    src="@/assets/images/home/icons/shou_icon_blue.png"
                    alt=""
                    @click="handleCollect(item)"
                    v-if="item.isCollect"
                  />
                  <img
                    src="@/assets/images/home/icons/shou_icon.png"
                    alt=""
                    @click="handleCollect(item)"
                    v-else
                  />
                  <span>{{ item.collectNum }}</span>
                </div>
                <div class="read2">
                  <img @click="handleRead(item)" :src="eye_icon" alt="" />
                  <span>{{ item.viewNum }}</span>
                </div>
              </div>
              <div class="name-time-wrapper flex">
                <div class="name">{{ item.teacher }}</div>
                <div>{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <div class="score flex flex-column align-c jus-c">
            <div>综合评分</div>
            <div>{{ item.divide }}</div>
          </div>
        </li>
      </ul>
      <div class="flex jus-c" style="padding: 220px 0;color: #000000;" v-else>暂无数据</div>
      <div class="pagination">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 40, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </article>
  </div>
</template>

<script>
import SearchSelect from "@/components/SearchSelect";
import { showRecord, collectItem } from "@/api/ApiConfig";

export default {
  components: {
    SearchSelect
  },
  props: {
    pageTotal: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ""
    },
    listData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isShowCollect: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    listData: {
      handler(newValue, _oldValue) {
        this.list2 = newValue;
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      personIcon: require("@/assets/images/login/person.png"),
      shou_icon: require("@/assets/images/home/icons/shou_icon.png"),
      eye_icon: require("@/assets/images/home/icons/eye_icon.png"),
      pageIndex: 1,
      pageSize: 10,
      list2: [
        // {
        //   name: "实验标题信息",
        //   content:
        //     "实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息........."
        // },
        // {
        //   name: "实验标题信息",
        //   content:
        //     "实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息实验内容信息........."
        // }
      ]
    };
  },
  mounted() {},
  methods: {
    handleChangeSearch(selc, selb) {
      this.$emit('change', selc, selb)
    },
    ToText(HTML) {
      var input = HTML;
      return input
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, "")
        .replace(/<[^>]+?>/g, "")
        .replace(/\s+/g, " ")
        .replace(/ /g, " ")
        .replace(/>/g, " ");
    },
    // 分页导航
    handleSizeChange(val) {
      this.pageSize = val;
      // this.getData();
      this.$emit("getPageSize", val);
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      // this.getData();
      this.$emit("getPageIndex", val);
    },
    handleClick(item) {
      this.doRead(item);
      if (this.type === "experiment") {
        localStorage.setItem('laboratory_id', item.id)
        localStorage.setItem('template', item.template)
        localStorage.setItem('pager', item.template === 'template1' ? 'pager1Home' : item.template === 'template2' ? 'pager2Home' : 'pager3Home')
        localStorage.setItem('pager_params', JSON.stringify({}))
        const href = this.$router.resolve({
          path: `/laboratory`,
          query: {},
        }).href;
        window.open(href, "_blank");
      }
    },
    // 收藏
    handleCollect(item) {
      collectItem({
        channel: this.type === "experiment" ? "实验" : "课程",
        id: item.id
      }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.$parent.getData();
      });
    },
    handleRead(item) {
      showRecord({
        channel: this.type === "experiment" ? "实验" : "课程",
        id: item.id
      }).then(res => {
        this.$parent.getData();
      });
    },
    doRead(item) {
      showRecord({
        channel: "实验",
        id: item.id
      }).then(res => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.HorizontalImgText {
  flex: 1;
  ul {
    list-style-type: none;
  }
  article {
    padding: 20px;
    margin-bottom: 40px;
    background: #ffffff;
    border-radius: 8px;
    .title-wrapper {
      border-bottom: 1px solid #e5e5e5;
      .name {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        border-bottom: 4px solid #3989eb;
        padding-bottom: 20px;
        margin-right: 18px;
      }
      .num {
        font-size: 16px;
        font-weight: 400;
        color: #3989eb;
        line-height: 40px;
      }
    }
    ul {
      padding-top: 10px;
      li {
        margin-bottom: 41px;
        color: #666666;
        .banner {
          width: 220px;
          height: 140px;
          border-radius: 8px;
          margin-right: 40px;
        }
        img {
          cursor: pointer;
        }
        .article-content {
          flex: 1;
          border-bottom: 1px solid #e5e5e5;
          padding: 10px 0 32px;
          .article-title {
            cursor: pointer;
            font-size: 16px;
            color: #333333;
            margin-bottom: 13px;
          }
          .ellipsis-multi-line {
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .articles {
            font-size: 14px;
            color: #666666;
            margin-bottom: 22px;
            p,
            div {
              font-weight: normal;
              color: #666666;
            }
            cursor: pointer;
          }
          .info {
            width: 100%;
          }
          .read-wrapper {
            font-family: Tensentype RuiHeiJ-W4;
            font-weight: 500;
            color: #333e55;

            img {
              vertical-align: middle;
              margin-right: 7px;
            }
            .read1 {
              margin-right: 40px;
              img {
                width: 15px;
                height: 15px;
              }
            }
            .read2 {
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
          .name-time-wrapper {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #666666;
            .name {
              margin-right: 39px;
            }
          }
        }
        .score {
          width: 86px;
          height: 86px;
          background: #eef5ff;
          border-radius: 4px;
          color: #3989eb;
          div:nth-child(1) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
          }
          div:nth-child(2) {
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
          }
        }
      }
    }
  }
  .SearchSelect {
    padding: 0;
  }
}
</style>
