<template>
  <div class="NewsDetail box_1200 ql-snow">
    <div class="Message ql-editor" v-html="content"></div>
    <div v-if="link" class="link">
      <i class="el-icon-paperclip"></i>
      <a :href="link" target="_blank" rel="">链接：{{ link }}</a>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {},
  data() {
    return {
      content: "",
      link: ""
    };
  },
  mounted() {
    if (this.$route.query) {
      this.content = this.$route.query.content || "";
      this.link = this.$route.query.link || "";
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.NewsDetail {
  padding-top: 109px;
  .Message {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
  }
  .link {
    padding-bottom: 20px;
  }
  ul {
    list-style-type: none;
  }
}
</style>
