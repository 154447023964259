<!-- 竖版 上面图片下边多文字 -->
<template>
  <div class="PortraitMore">
    <article>
      <div class="title-wrapper flex">
        <div class="name">新闻</div>
        <div class="num" v-if="isShowCollect">收藏共{{ pageTotal }}条</div>
      </div>
      <ul class="flex flex-wrap" v-if="list.length > 0">
        <li v-for="(item, index) in list" :key="index">
          <div @click="pathDetail(item)">
            <img :src="item.pic" alt="" class="pic" />
            <div class="article-title one-word-ellipsis">{{ item.title }}</div>
            <div class="articles four-word-ellipsis word-break">
              {{ ToText(item.filterContent) }}
            </div>
          </div>
          <div class="read-wrapper flex jus-b">
            <div class="read1">
              <img
                src="@/assets/images/home/icons/shou_icon_blue.png"
                alt=""
                @click="doCollect(item)"
                v-if="item.isCollect"
              />
              <img :src="shou_icon_grey" alt="" @click="doCollect(item)" v-else />
              <span>{{ item.collectNum || 0 }}</span>
            </div>
            <div class="read2">
              <img :src="eye_icon" alt="" />
              <span>{{ item.viewNum || 0 }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="flex jus-c" style="padding: 220px 0;color: #000000;" v-else>暂无数据</div>
      <div class="pagination">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 40, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </article>
  </div>
</template>
<script>
import { showRecord, collectItem } from "@/api/ApiConfig";
export default {
  components: {},
  props: {
    pageTotal: {
      type: Number,
      default: 0
    },
    listData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isShowCollect: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    // isShowCollect: {
    //   handler(newValue, oldValue) {
    //     console.log(newValue, "isShowCollect");
    //   },
    //   deep: true,
    //   immediate: true
    // },
    listData: {
      handler(newValue, oldValue) {
        if (newValue && newValue.length > 0) {
          newValue.map(item => {
            this.$set(
              item,
              "filterContent",
              item.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "")
            );
          });
          this.list = newValue;
        } else {
          this.list = [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      personIcon: require("@/assets/images/login/person.png"),
      shou_icon_blue: require("@/assets/images/home/icons/shou_icon_blue.png"),
      shou_icon_grey: require("@/assets/images/home/icons/shou_icon.png"),
      eye_icon: require("@/assets/images/home/icons/eye_icon.png"),
      pageIndex: 1,
      pageSize: 10,
      list: [
        // {
        //   name: "淘宝网XXXXXX",
        //   content:
        //     "淘宝网创立于2003年，是以商务为导向的社交平台，通过大数据分析为消费者提供既有参与感又个性化的购物体验"
        // },
        // {
        //   name: "淘宝网XXXXXX",
        //   content:
        //     "淘宝网创立于2003年，是以商务为导向的社交平台，通过大数据分析为消费者提供既有参与感又个性化的购物体验"
        // },
        // {
        //   name: "淘宝网XXXXXX",
        //   content:
        //     "淘宝网创立于2003年，是以商务为导向的社交平台，通过大数据分析为消费者提供既有参与感又个性化的购物体验"
        // },
        // {
        //   name: "淘宝网XXXXXX",
        //   content:
        //     "淘宝网创立于2003年，是以商务为导向的社交平台，通过大数据分析为消费者提供既有参与感又个性化的购物体验"
        // },
        // {
        //   name: "淘宝网XXXXXX",
        //   content:
        //     "淘宝网创立于2003年，是以商务为导向的社交平台，通过大数据分析为消费者提供既有参与感又个性化的购物体验"
        // }
      ]
    };
  },
  created() {},
  methods: {
    ToText(HTML) {
      var input = HTML;
      return input
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, "")
        .replace(/<[^>]+?>/g, "")
        .replace(/\s+/g, " ")
        .replace(/ /g, " ")
        .replace(/>/g, " ");
    },
    doCollect(item) {
      this.openLoading();
      collectItem({
        channel: "新闻",
        id: item.id
      }).then(res => {
        this.openLoading().close();
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.$parent.getData();
      });
    },
    pathDetail(item) {
      this.doRead(item);
      this.$router.push({
        // name: "详情",
        // params: item
        path: "/newsDetail",
        query: item
      });
    },
    doRead(item) {
      showRecord({
        channel: "新闻",
        id: item.id
      }).then(res => {});
    },
    // 分页导航
    handleSizeChange(val) {
      this.pageSize = val;
      this.$emit("getPageSize", val);
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.$emit("getPageIndex", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.PortraitMore {
  width: 970px;
  ul {
    list-style-type: none;
  }
  .read-wrapper {
    font-family: Tensentype RuiHeiJ-W4;
    font-weight: 500;
    color: #333e55;
    padding-top: 10px;
    img {
      vertical-align: middle;
      margin-right: 7px;
    }
    .read1 {
      margin-right: 40px;
      img {
        width: 15px;
        height: 17px;
      }
    }
    .read2 {
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
  article {
    padding: 29px 40px 40px;
    margin-bottom: 40px;
    background: #ffffff;
    border-radius: 8px;
    .title-wrapper {
      border-bottom: 1px solid #e5e5e5;
      .name {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
        border-bottom: 4px solid #3989eb;
        padding-bottom: 20px;
        margin-right: 18px;
      }
      .num {
        font-size: 16px;
        font-weight: 400;
        color: #3989eb;
        line-height: 40px;
      }
    }
    ul {
      padding: 39px 0 0 10px;
      li {
        margin-bottom: 61px;
        width: 270px;
        text-align: center;
        margin-right: 23px;
        cursor: pointer;
        .pic {
          width: 270px;
          height: 180px;
          background: #5de6ff;
          box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.6);
          border-radius: 8px;
          margin-bottom: 30px;
        }
        .article-title {
          color: #333333;
          margin-bottom: 12px;
        }
        .articles {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
}
</style>
