<template>
  <div class="record-pager">
    <div class="detail">
      <div class="inner">
        <div class="name">学习记录</div>
        <el-table :data="records" style="width: 100%">
          <el-table-column prop="videoLink" align="center" label="姓名" min-width="120"></el-table-column>
          <el-table-column prop="videoLink" align="center" label="实验名称" min-width="150"></el-table-column>
          <el-table-column prop="videoLink" align="center" label="实验结果" min-width="120"></el-table-column>
          <el-table-column prop="videoLink" align="center" label="实验开始时间" min-width="150"></el-table-column>
          <el-table-column prop="videoLink" align="center" label="实验结束时间" min-width="150"></el-table-column>
          <el-table-column prop="videoLink" align="center" label="实验用时" min-width="150"></el-table-column>
          <el-table-column prop="videoLink" align="center" label="实验成绩" min-width="120"></el-table-column>
          <el-table-column prop="videoLink" align="center" label="实验报告" min-width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 40, 50, 100]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { queryStudyDetail } from "@/api/ApiConfig.js";

export default {
  props: ['pager', 'params'],
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      records: []
    };
  },
  computed: {
  },
  mounted() {
    this.getData()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    getData() {
      // queryStudyDetail({
      //   page: this.data.page,
      //   size: this.data.size
      // }).then(res => {
      //   if (res.code === 200) {
      //     this.chapterList = res.data.rows || [];
      //     this.total = res.data.total
      //   }
      // });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    handleDetail(row) {
      // console.log(row)
    }
  }
};
</script>
<style lang="scss" scoped>
.record-pager {
  .detail {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    .inner {
      font-size: 14px;
      color: #666666;
      line-height: 2;
      opacity: 0.86;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        margin-bottom: 10px;
      }
      .pagination {
        margin-top: 20px;
      }
    }
  }
}
</style>
