<template>
  <el-container class="index-con">
    <el-container class="main-con">
      <el-main>
        <!-- 面包屑导航 -->
        <Header />
        <div class="content-wrapper flex jus-c flex-column align-c">
          <Breadcrum :subTitle="subTitle" v-if="subTitle !== '首页' && subTitle !== '资料文件' && subTitle !== '题库' && subTitle !== 'API' && subTitle" />
          <router-view></router-view>
          <Footer />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// 导入组件
import Breadcrum from "@/components/breadcrum.vue";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "index",
  // 注册组件
  components: {
    Breadcrum,
    Header,
    Footer
  },
  data() {
    return {
      showclass: "asideshow",
      showtype: false,
      subTitle: localStorage.getItem("routeName")
    };
  },
  watch: {
    $route(to) {
      this.subTitle = to.name;
      localStorage.setItem("routeName", to.name);
    }
  },
  methods: {},
  created() {
    
    // 监听
    // this.$root.Bus.$on("toggle", value => {
    //   if (value) {
    //     this.showclass = "asideshow";
    //   } else {
    //     setTimeout(() => {
    //       this.showclass = "aside";
    //     }, 300);
    //   }
    // });
  },
  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  beforeMount() {
    // 弹出登录成功
    // this.$message({
    //   message: "登录成功",
    //   type: "success"
    // });
  }
};
</script>
<style lang="scss" scoped>
.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.aside {
  width: 64px !important;
  height: 100%;
  background-color: #265da4;
  margin: 0px;
}
.asideshow {
  width: 240px !important;
  height: 100%;
  background-color: #265da4;
  margin: 0px;
}
/deep/.el-main {
  // padding-top: 109px;
  padding: 0;
}
.content-wrapper {
  margin: 0 auto;

  // position: relative;
  // width: 1200px;
}
.breadcrumb {
  position: absolute;
  top: 109px;
  width: 1200px;
}
/deep/.el-breadcrumb__item {
  .el-breadcrumb__separator {
    color: #3989eb !important;
  }
  .el-breadcrumb__inner {
    &.is-link {
      font-size: 14px !important;
      font-family: Microsoft YaHei !important;
      font-weight: 400 !important;
      color: #666666 !important;
    }
  }
  &:last-child {
    .el-breadcrumb__inner {
      font-weight: bold !important;
      color: #3989eb !important;
    }
  }
}
</style>
