<template>
  <div class="home-pager">
    <div class="homeTop">
      <div class="inner">
        <div class="title">{{ title }}</div>
        <div class="lab-info flex jc-between ai-normal">
          <div class="left flex-1 flex flex-column jc-between ai-normal">
            <el-rate v-model="average" disabled class="rate" :colors="['#FF5D26', '#FF5D26', '#FF5D26']" disabled-void-color="#A8CFFF"></el-rate>
            <div class="flex-1">
              <div class="from">所属实验：{{ title }} 讲师：{{ teacher }}</div>
              <div class="desc" v-html="studyPrinciple.replace(/<[^>]+>/g, '')"></div>
            </div>
            <el-select class="phase" v-model="studyId" placeholder="请选择" @change="handleChangeStudyId()">
              <el-option
                v-for="item in studyIdList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <div class="btns flex ai-center">
              <el-button class="btn" type="primary" @click="handleParticipation()">参与实验</el-button>
              <el-button class="btn" type="primary" @click="handleExam()">我要考试</el-button>
              <el-button class="btn red-btn flex ai-center" type="warning" @click="handleCollect()">收藏<img class="icon" :src="isCollect ? require('../images/collect-actived.png') : require('../images/collect.png')" /></el-button>
              <el-button class="btn red-btn flex ai-center" type="warning" @click="handlePraise()">点赞<img class="icon" :src="isLike ? require('../images/praise-actived.png') : require('../images/praise.png')" /></el-button>
            </div>
          </div>
          <div class="right relative">
            <video
              class="video"
              ref="videoRef"
              width="900px"
              disablePictureInPicture
              controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
              controls
              autoplay
              :src="video_link"
            />
            <div class="video-btns absolute">
              <el-button :class="['video-btn', { 'actived': video_link == video }]" type="primary" @click="handleChangeVideo(video)">实验简介视频</el-button>
              <el-button :class="['video-btn', { 'actived': video_link == videoBlurb }]" type="primary" @click="handleChangeVideo(videoBlurb)">实验引导视频</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Navigate :pager="pager" :params="params" @change="changePager" />
    <div class="team">
      <div class="first-title text-align-center">教学服务团队</div>
      <div class="second-title text-align-center">专业名师辅导</div>
      <el-carousel height="380px" :interval="5000" arrow="always" autoplay loop class="carousel">
        <el-carousel-item v-for="(item, index) in teams" :key="index">
          <div class="carousel-item flex ai-center">
            <div v-for="(child, idx) in item" :key="idx" class="team-item flex flex-column jc-between ai-center">
              <img class="photo" :src="child.photo" />
              <div v-if="index === 0 && idx === 0" class="team-header">实验项目负责人</div>
              <div class="name">{{ child.name }}</div>
              <div class="desc">{{ child.desc }}</div>
              <el-button class="btn" size="small" type="primary" @click="handleTeamDeatil(child)">详情</el-button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="more flex jc-center ai-center cursor-pointer" @click="goTeam()">
        <div class="txt">查看更多</div>
        <img class="icon" src="../images/right-icon.png" />
      </div>
    </div>
    <div class="chart">
      <div class="first-title text-align-center">实验项目情况</div>
      <div class="second-title text-align-center">精简数据对比</div>
      <div class="bar-chart" id="bar-chart"></div>
    </div>
    <div class="project">
      <div class="first-title text-align-center">实验系统浏览器要求与推荐实验</div>
      <div class="second-title text-align-center">多应用多模式多思维</div>
      <div class="inner flex ai-center">
        <div class="browser flex ai-center">
          <div v-for="(item, index) in browser" :key="index" class="browser-item flex flex-column jc-between ai-center">
            <div class="name">{{ item.name }}</div>
            <img class="img" :src="item.icon" />
          </div>
        </div>
        <div class="recommend">
          <div class="flex jc-between ai-center">
            <div class="name">{{ recommend.name }}</div>
            <div class="people flex ai-center">
              <img class="icon" src="../images/people-icon2.png" />
              <div class="num">{{ recommend.viewNum }}人学习</div>
            </div>
          </div>
          <div class="line"></div>
          <div class="auther">{{ recommend.teacher }}</div>
          <div class="desc" v-html="recommend.content.replace(/<[^>]+>/g, '')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import Navigate from "./components/navigate";
import { studyModuleList, queryStudyDetail, studyJoinNum, comprehensiveDivide, showRecord, classRecommend, gotoClass, collectItem, studyLike } from "@/api/ApiConfig";

export default {
  components: { Navigate },
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      studyIdList: [],
      studyId: null,
      myChart: null,
      chartData: [],
      title: '',
      average: -1,
      teacher: '',
      studyPrinciple: '',
      isCollect: false,
      isLike: false,
      video: '',
      videoBlurb: '',
      video_link: '',
      studyLink: '',
      testLink: '',
      teams: [],
      browser: [
        {
          name: '谷歌浏览器',
          icon: require('../images/chrome.png')
        },
        {
          name: '火狐浏览器',
          icon: require('../images/firefox.png')
        },
        {
          name: 'QQ浏览器',
          icon: require('../images/qq.png')
        }
      ],
      recommend: {
        name: "--",
        teacher: '--',
        viewNum: 0,
        content: '--'
      }
    };
  },
  computed: {
    chartOpt() {
      return {
        grid: {
          top: 60,
        },
        color: [
          {
            type: 'linear',
            x: 0, y: 0, x2: 0, y2: 1,
            colorStops: [{ offset: 0, color: 'rgba(50, 124, 237, 1)' }, { offset: 1, color: 'rgba(50, 124, 237, 0.2)' }],
            global: false
          },
          {
            type: 'linear',
            x: 0, y: 0, x2: 0, y2: 1,
            colorStops: [{ offset: 0, color: 'rgba(255, 117, 70, 1)' }, { offset: 1, color: 'rgba(255, 117, 70, 0.2)' }],
            global: false
          }
        ],
        legend: {
          data: ['实验项目使用量', '实验项目浏览量'],
          top: 10
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: this.chartData.map(item => item.time)
        },
        yAxis: {
          name: '人次',
          nameTextStyle: {
            padding: [0, 0, 30, 0]
          },
          type: 'value',
          minInterval: 1,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            name: '实验项目使用量',
            barWidth: 24,
            itemStyle: {
              barBorderRadius: 12
            },
            data: this.chartData.map(item => parseFloat(item.studyUseNum))
          },
          {
            type: 'bar',
            name: '实验项目浏览量',
            barWidth: 24,
            itemStyle: {
              barBorderRadius: 12
            },
            data: this.chartData.map(item => parseFloat(item.studyViewNum))
          }
        ]
      }
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async handleChangeStudyId() {
      localStorage.setItem('laboratory_id', this.studyId)
      this.laboratory_id = this.studyId
      this.$emit('change', { pager: 'pager1Home', params: {} })
      await this.init()
      this.studyId = null
    },
    async init() {
      await this.queryStudyDetail()
      await this.comprehensiveDivide()
      await this.studyJoinNum()
      this.initChart()
      this.showRecord()
      this.classRecommend()
    },
    classRecommend() {
      classRecommend({
        channel: '实验'
      }).then(res => {
        if (res.code === 200) {
          this.recommend = {
            name: res.data.length > 0 ? res.data[0].name : "--",
            teacher: res.data.length > 0 ? res.data[0].teacher : "--",
            viewNum: res.data.length > 0 ? res.data[0].viewNum || 0 : 0,
            content: res.data.length > 0 ? res.data[0].content : "--"
          };
        }
      });
    },
    async studyJoinNum() {
      const res = await studyJoinNum({
        channel: '实验',
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.chartData = res.data.reverse()
      }
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      const res_list = await studyModuleList({
        channel: "实验",
        listType: '',
        type: '',
        type2: '',
        pageIndex: 1,
        pageSize: 999999
      })
      if (res_list.code === 200) {
        this.studyIdList = res_list.data.list.filter(item => res.data.studyIdList.split(',').includes(item.id)).map(item => ({
          id: item.id,
          name: item.name
        }))
      }
      if (res.code === 200) {
        this.title = res.data.name
        this.teacher = res.data.teacher
        this.studyPrinciple = res.data.studyPrinciple || ''
        this.isCollect = res.data.isCollect
        this.isLike = res.data.isLike
        this.video = res.data.video
        this.videoBlurb = res.data.videoBlurb
        this.video_link = this.video
        this.studyLink = res.data.studyLink
        this.testLink = res.data.testLink
        const teams = [JSON.parse(res.data.teamHead), ...JSON.parse(res.data.teamUser)]
        teams.map((item, index) => {
          if (index % 4 === 0) {
            this.teams.push([])
            this.teams[parseInt(index / 4)].push(item)
          } else {
            this.teams[parseInt(index / 4)].push(item)
          }
        })
      }
    },
    async comprehensiveDivide() {
      const res = await comprehensiveDivide({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.average =  Number(
          ((Number(res.data.content) + Number(res.data.feature) + Number(res.data.passingRate)) / 3).toFixed(1)
        )
      }
    },
    showRecord() {
      showRecord({
        channel: '实验',
        id: this.laboratory_id
      })
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById("bar-chart"));
      this.myChart.setOption(this.chartOpt);
      window.addEventListener('resize', () => {
        this.myChart.resize();
      });
    },
    handleChangeVideo(video_link) {
      this.video_link = video_link
    },
    goTeam() {
      this.$emit('change', { pager: "pager1Team", params: {} })
    },
    async handleParticipation() {
      if (this.studyLink.includes("http")) {
        await gotoClass({
          channel: '实验',
          id: this.laboratory_id
        })
        window.open(this.studyLink, "_blank");
      } else {
        this.$message.warning('实验系统正在开发中...')
      }
    }, 
    handleExam() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      }
      if (this.testLink.includes("http")) {
        window.open(this.testLink, "_blank");
      } else {
        this.$message.warning('考试系统正在开发中...')
      }
    },
    handleCollect() {
      collectItem({
        channel: "实验",
        id: this.laboratory_id
      }).then(async res => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        await this.queryStudyDetail();
        if (this.isCollect) {
          this.$message.success("收藏成功");
        } else {
          this.$message.success("已取消收藏");
        }
      });
    },
    handleTeamDeatil(child) {
      this.$alert(child.desc, child.name)
    },
    handlePraise() {
      studyLike({
        channel: "实验",
        id: this.laboratory_id
      }).then(async res => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        await this.queryStudyDetail();
        if (this.isLike) {
          this.$message.success("点赞成功");
        } else {
          this.$message.success("已取消点赞");
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.home-pager {
  .first-title {
    height: 42px;
    line-height: 42px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .second-title {
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    font-weight: bold;
    color: #234984;
    margin-top: 6px;
  }
  .homeTop {
    margin: 0 auto 25px;
    background: url('../images/lab-bg1.png') no-repeat center center;
    background-size: 100% 100%;
    .inner {
      width: 1240px;
      height: 600px;
      overflow: hidden;
      margin: 0 auto;
    }
    .title {
      font-size: 56px;
      font-weight: bold;
      margin: 59px 0 14px;
    }
    .lab-info {
      .left {
        .from {
          font-size: 20px;
          color: #333333;
          line-height: 28px;
          margin: 40px 0 20px;
        }
        .desc {
          font-size: 18px;
          color: #666666;
          line-height: 25px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
        .phase {
          margin: 10px 0 24px;
          width: 394px;
          // /deep/.el-button {
          //   width: 100%;
          // }
        }
        .btns {
          .btn {
            background: #327CED;
            .icon {
              width: 32px;
              height: 32px;
              margin: -11px -11px -11px 0;
            }
          }
          .red-btn {
            background: #FF5D26;
          }
        }
      }
      .right {
        margin-left: 55px;
        .video {
          width: 580px;
          height: 372px;
        }
        .video-btns {
          bottom: -40px;
          left: 50%;
          transform: translateX(-50%);
          .video-btn {
            background: none;
            margin: 0 7px;
            border: solid 1px #327CED;
            color: #327CED;
            &.actived {
              background: #327CED;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .team {
    width: 1336px; 
    margin: 25px auto;
    .carousel-item {
      width: 100%;
      height: 380px;
    }
    .team-item {
      width: 270px;
      height: 340px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
      border-radius: 10px;
      margin: 40px 20px 0;
      position: relative;
      .photo {
        width: 180px;
        height: 190px;
        margin-top: -40px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
      }
      .team-header {
        position: absolute;
        left: 45px;
        top: 105px;
        width: 142px;
        height: 30px;
        line-height: 30px;
        z-index: 2;
        background: url('../images/team-header.png') left center;
        background-size: 100% 100%;
        padding-left: 10px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
      }
      .name {
        height: 26px;
        line-height: 26px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin: 14px 0 10px;
      }
      .desc {
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        padding: 0 18px;
      }
      .btn {
        margin: 10px 0 20px;
      }
    }
    .carousel {
      padding: 0 48px;
      margin: 25px 0;
    }
    /deep/.el-carousel__indicators--horizontal {
      display: none;
    }
    /deep/.el-carousel__arrow--left {
      transform: translateX(-60px);
      width: 68px;
      height: 68px;
      background: url('../images/arrow-left.png') no-repeat center center;
      background-size: 100% 100%;
      .el-icon-arrow-left {
        display: none;
      }
    }
    /deep/.el-carousel__arrow--right {
      transform: translateX(60px);
      width: 68px;
      height: 68px;
      background: url('../images/arrow-right.png') no-repeat center center;
      background-size: 100% 100%;
      .el-icon-arrow-right {
        display: none;
      }
    }
    .more {
      width: 98px;
      height: 40px;
      margin: 0 auto;
      .txt {
        font-size: 16px;
        font-weight: bold;
        color: #FF5D26;
        line-height: 22px;
      }
      .icon {
        width: 14px;
        height: 14px;
        margin-left: 7px;
      }
    }
  }
  .chart {
    width: 100%;
    max-width: 1200px;
    margin: 25px auto;
    .bar-chart {
      width: 100%;
      max-width: 1200px;
      height: 400px;
      padding-top: 20px;
    }
  }
  .project {
    width: 100%;
    max-width: 1200px;
    margin: 25px auto;
    .inner {
      width: 100%;
      max-width: 1240px;
      height: 258px;
      margin: 25px 0 50px;
      .browser {
        .browser-item {
          width: 206px;
          height: 258px;
          margin: 0 20px;
          background: #fff;
          border-radius: 10px;
          padding: 30px 0 7px;
          box-sizing: border-box;
          .name {
            height: 25px;
            line-height: 25px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
          }
          .img {
            width: 114px;
            height: 114px;
          }
        }
      }
      .recommend {
        width: 536px;
        height: 332px;
        padding: 68px;
        box-sizing: border-box;
        background: url('../images/recommend-bg.png') no-repeat left 0 top 0;
        background-size: 100% 100%;
        border-radius: 10px;
        .name {
          height: 25px;
          line-height: 25px;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .icon {
          width: 27px;
          height: 21px;
        }
        .num {
          height: 25px;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 25px;
          margin-left: 10px;
        }
        .line {
          width: 402px;
          height: 1px;
          margin: 18px 0 20px;
          background: url('../images/line.png') no-repeat center center;
          background-size: 100% 100%;
        }
        .auther {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: #FFFFFF;
        }
        .desc {
          font-size: 14px;
          color: #FFFFFF;
          line-height: 20px;
          margin-top: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
