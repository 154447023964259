<template>
  <div class="files-pager">
    <div class="detail filesPager">
      <div class="inner flex flex-wrap">
        <div v-for="(item, index) in videoList" :key="index" class="item flex flex-column jc-between ai-center cursor-pointer" @click="handleDownload(item)">
          <img v-if="item.videoAddr.split('.')[item.videoAddr.split('.').length - 1] == 'pdf'" class="icon" src="../../images/pdf2.png" />
          <img v-else-if="['docx', 'doc'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/doc2.png" />
          <img v-else-if="['txt'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/txt2.png" />
          <img v-else-if="['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/video2.png" />
          <img v-else-if="['ppt', 'pptx', 'pps', 'ppsx'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/ppt2.png" />
          <img v-else-if="['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/image2.png" />
          <img v-else-if="['html'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/article2.png" />
          <img v-else class="chapter_icon" src="../../images/file2.png" />
          <div class="name text-align-center">{{ item.videoAddr ? item.videoAddr.split("/")[item.videoAddr.split("/").length - 1] : "" }}</div>
        </div>
        <div v-for="(item, index) in videoList" :key="index" class="item flex flex-column jc-between ai-center" @click="handleDownload(item)">
          <img v-if="item.videoAddr.split('.')[item.videoAddr.split('.').length - 1] == 'pdf'" class="icon" src="../../images/pdf2.png" />
          <img v-else-if="['docx', 'doc'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/doc2.png" />
          <img v-else-if="['txt'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/txt2.png" />
          <img v-else-if="['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/video2.png" />
          <img v-else-if="['ppt', 'pptx', 'pps', 'ppsx'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/ppt2.png" />
          <img v-else-if="['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/image2.png" />
          <img v-else-if="['html'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/article2.png" />
          <img v-else class="chapter_icon" src="../../images/file2.png" />
          <div class="name text-align-center">{{ item.videoAddr ? item.videoAddr.split("/")[item.videoAddr.split("/").length - 1] : "" }}</div>
        </div>
        <div v-for="(item, index) in videoList" :key="index" class="item flex flex-column jc-between ai-center" @click="handleDownload(item)">
          <img v-if="item.videoAddr.split('.')[item.videoAddr.split('.').length - 1] == 'pdf'" class="icon" src="../../images/pdf2.png" />
          <img v-else-if="['docx', 'doc'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/doc2.png" />
          <img v-else-if="['txt'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/txt2.png" />
          <img v-else-if="['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/video2.png" />
          <img v-else-if="['ppt', 'pptx', 'pps', 'ppsx'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/ppt2.png" />
          <img v-else-if="['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/image2.png" />
          <img v-else-if="['html'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/article2.png" />
          <img v-else class="chapter_icon" src="../../images/file2.png" />
          <div class="name text-align-center">{{ item.videoAddr ? item.videoAddr.split("/")[item.videoAddr.split("/").length - 1] : "" }}</div>
        </div>
        <div v-for="(item, index) in videoList" :key="index" class="item flex flex-column jc-between ai-center" @click="handleDownload(item)">
          <img v-if="item.videoAddr.split('.')[item.videoAddr.split('.').length - 1] == 'pdf'" class="icon" src="../../images/pdf2.png" />
          <img v-else-if="['docx', 'doc'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/doc2.png" />
          <img v-else-if="['txt'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/txt2.png" />
          <img v-else-if="['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/video2.png" />
          <img v-else-if="['ppt', 'pptx', 'pps', 'ppsx'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/ppt2.png" />
          <img v-else-if="['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/image2.png" />
          <img v-else-if="['html'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])" class="icon" src="../../images/article2.png" />
          <img v-else class="chapter_icon" src="../../images/file2.png" />
          <div class="name text-align-center">{{ item.videoAddr ? item.videoAddr.split("/")[item.videoAddr.split("/").length - 1] : "" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryStudyDetail } from "@/api/ApiConfig.js";

export default {
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      videoList: [],
      dowland: '0'
    };
  },
  watch: {
    pager() {
      this.queryStudyDetail()
    },
    params() {
      this.queryStudyDetail()
    }
  },
  mounted() {
    this.queryStudyDetail()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        this.videoList = res.data.videoList
        this.dowland = res.data.dowland
      }
    },
    handlePreview(item) {
      if(this.dowland === '1') {
        if(['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])) {
          this.$alert(`<img src=${item.videoAddr} style='position: fixed; left: 50%; top: 50%; margin-top: 0px; z-index: 9999; transform: translate(-50%, -50%); max-width: 80%; width: 80%; max-height: 80%; object-fit: contain;' />`, '', {
            dangerouslyUseHTMLString: true,
            showClose: true,
            closeOnClickModal: true,
            showConfirmButton: false,
            center: true,
            customClass: 'files-dialog'
          });
        } else if(['mp4', 'avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob'].includes(item.videoAddr.split('.')[item.videoAddr.split('.').length - 1])) {
          this.$alert(`<video src=${item.videoAddr} style='position: fixed; left: 50%; top: 50%; margin-top: 0px; z-index: 9999; transform: translate(-50%, -50%); max-width: 80%; width: 80%; max-height: 80%; object-fit: contain;' autoplay controls />`, '', {
            dangerouslyUseHTMLString: true,
            showClose: true,
            closeOnClickModal: true,
            showConfirmButton: false,
            center: true,
            customClass: 'files-dialog'
          });
        } else {
          window.open(item.videoAddr)
        }
      } else {
        this.$alert('文件不允许查看', '提示', { confirmButtonText: '确定' });
        // this.$message.error('文件不允许查看')
      }
    },
    handleDownload(item) {
      if(this.dowland === '1') {
        window.open(item.videoAddr)
      } else {
        this.$alert('文件不允许查看', '提示', { confirmButtonText: '确定' });
        // this.$message.error('文件不允许查看')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.files-pager {
  .detail {
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    .inner {
      width: 1156px;
      opacity: 0.86;
      margin-top: 15px;
      .item {
        width: 170px;
        margin: 15px 30px;
        .icon {
          width: 38px;
          height: 38px;
        }
        .name {
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.files-dialog.el-message-box {
  width: 90% !important;
  height: 90% !important;
}
</style>
