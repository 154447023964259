<template>
  <div class="SearchSelect ">
    <div class="courseClass">
      {{ from_type === 'course' ? '课程分类：' : '实验分类：' }}
      <span v-for="(item, index) in clasList" :key="index" @click="handleChange('selc', item.type)" :class="[{ 'actived': selc == item.type }]">
        {{ item.type }}
      </span>
    </div>
    <div class="publicYear">
        发布年份：
      <span v-for="(item, index) in publList" :key="index" @click="handleChange('selb', item.type)" :class="[{ 'actived': selb == item.type }]">
        {{ item.type }}
      </span>
      <span v-if="!showMore" @click="handleMore()">
        更早
      </span>
    </div>
  </div>
</template>
<script>
import { studyType2 } from "@/api/ApiConfig.js";
export default {
  components: {},
  props: {
    from_type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      clasList: [],
      publList: [
        { type: "全部" },
        { type: "2024" },
        { type: "2023" }
      ],
      selb: '全部',
      selc: '全部',
      type: "",
      showMore: false
    };
  },
  created() {
    this.getStudyType();
  },
  activated() {
    this.getStudyType();
  },
  methods: {
    getStudyType() {
      studyType2({
        channel: this.from_type === 'course' ? "课程" : '实验' 
      }).then(res => {
        if (res.code === 200) {
          this.clasList = [{type: '全部'}, ...res.data.filter(item => item)]
        }
      });
    },
    handleMore() {
      this.publList = [
        { type: "全部" },
        { type: "2024" },
        { type: "2023" },
        { type: "2022" },
        { type: "2021" },
        { type: "2020" }
      ]
      this.showMore = true
    },
    handleChange(sel, type) {
      this[sel] = type
      this.$emit('change', this.selc, this.selb);
    }
  }
};
</script>
<style lang="scss">
.SearchSelect {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  .courseClass, .publicYear {
    span {
      color: #0A1624;
      font-size: 14px;
      display: inline-block;
      width: 100px;
      text-align: center;
      height: 32px;
      line-height: 32px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 16px;
      margin: 10px 15px 10px 0;
      cursor: pointer;
      &.actived {
        background: rgba(57,137,235,0.4);
        color: #3989EB;
        font-weight: bold;
      }
    }
  }
  .publicYear {
    margin-top: 9px;
  }
}
</style>
