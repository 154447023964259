<template>
  <ul class="menu2-wrapper">
    <li
      class="main_tab"
      v-for="item in ruleCodes"
      :key="item.value"
      :label="item.value"
      @click="handleClick(item)"
      :class="number == item.value ? 'blue' : ''"
    >
      <img :src="item.unCheckIcon" alt="" v-if="number == item.value" />
      <img :src="item.CheckIcon" alt="" v-else />
      <span>{{ item.label }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      ruleCodes: [
        {
          label: "新闻信息",
          value: "1",
          CheckIcon: require("@/assets/images/icon/news-blue.png"),
          unCheckIcon: require("@/assets/images/icon/news-white.png")
        },
        {
          label: "通知信息",
          value: "2",
          CheckIcon: require("@/assets/images/icon/notice-blue.png"),
          unCheckIcon: require("@/assets/images/icon/notice-white.png")
        }
      ],
      number: "1",
      routeName: ""
    };
  },
  watch: {
    routeName: {
      handler(newValue, oldValue) {
        if (newValue == "新闻") {
          this.number = 1;
        } else if (newValue == "通知") {
          this.number = 2;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.routeName = localStorage.getItem("routeName");
  },
  mounted() {},
  methods: {
    // tab点击事件
    handleClick(item) {
      this.number = item.value;
      if (this.number == 1) {
        var path = "/news";
      } else if (this.number == 2) {
        var path = "/news/notifyMessage";
      }
      this.$router.push({
        path
      });
    }
  }
};
</script>
