<template>
  <div class="bannerContent flex jus-b  box_1200">
    <div class="left">
      <div class="title flex jus-b align-c">
        <p class="title1">{{ type }}封面信息</p>
        <div class="title2 flex jus-b">
          <p>
            <span class="label">讲师：</span>
            <span class="value">{{ data.teacher }}</span>
          </p>
          <!-- <p>
            <span class="label">所属：</span>
            <span class="value">{{ data.suoshu}}</span>
          </p> -->
        </div>
      </div>
      <div class="videoBox">
        <video v-if="data.videoSrc" controls :src="data.videoSrc" />
        <img v-else :src="data.pic" alt="" />
        <div class="button">
          <el-button type="primary" @click="handleVideo('video')">{{ type }}简介视频</el-button>
          <el-button @click="handleVideo('videoBlurb')">{{ type }}引导视频</el-button>
        </div>
      </div>
    </div>
    <div class="right">
      <p class="title flex jus-c">{{ type }}详细信息</p>
      <div class="content">
        <div class="describe flex jus-b">
          <p>所属{{ type }}：{{ data.name }}</p>
          <p>上线时间：{{ data.createTime }}</p>
        </div>
        <div class="text ql-editor" v-html="data.filterContent"></div>
        <div class="mark flex align-c">
          <p class="mark flex align-c">
            <img
              src="@/assets/images/home/icons/shou_icon_blue.png"
              @click="handleCollect('2')"
              alt=""
              v-if="data.isCollect"
            />
            <img
              src="@/assets/images/home/icons/shou_icon.png"
              alt=""
              @click="handleCollect('1')"
              v-else
            />
            <span>{{ data.collectNum }}</span>
          </p>
          <p class="mark flex align-c">
            <img @click="handleRead" src="@/assets/images/home/icons/eye_icon.png" alt="" />
            <span>{{ data.viewNum }}</span>
          </p>
        </div>
        <div class="button">
          <el-button class="btn1" size="mini" type="primary" @click="handleClass">{{
            type === "实验" ? "参与实验" : "我要上课"
          }}</el-button>
          <el-button class="btn2" size="mini" type="primary" @click="handleExam('我要考试')"
            >我要考试</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>{{
        tipValue == "我要考试"
          ? "考试系统正在开发中"
          : tipValue == "我要上课"
          ? "上课系统正在开发中..."
          : "实验系统正在开发中..."
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { queryStudyDetail, collectItem, showRecord } from "@/api/ApiConfig";
export default {
  data() {
    return {
      shou_icon: require("@/assets/images/home/icons/shou_icon.png"),
      dialogVisible: false,
      tipValue: "我要考试",
      data: {}
    };
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleClick(val) {},
    getData() {
      queryStudyDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          let data = res.data;
          data.filterContent = data.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "");

          this.data = data;
          this.$set(
              this.data,
              "videoSrc",
              data.video
            );
        }
      });
    },
    // 收藏
    handleCollect(type) {
      collectItem({
        channel: this.type === "experiment" ? "实验" : "课程",
        id: this.$route.query.id
      }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.getData();
        if (type === "1") {
          this.$message.success("收藏成功");
        } else {
          this.$message.success("已取消收藏");
        }
      });
    },
    handleRead() {
      showRecord({
        channel: this.type === "experiment" ? "实验" : "课程",
        id: this.$route.query.id
      }).then(res => {
        this.$parent.getData();
      });
    },
    // 我要上课
    handleClass() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      }
      if (this.type === "实验") {
        this.tipValue = "参与实验";
        if (this.data.studyLink.includes("http")) {
          window.open(this.data.studyLink, "_blank");
        } else {
          this.dialogVisible = true;
        }
      } else {
        this.tipValue = "我要上课";
        showRecord({
          channel: "课程",
          id: this.data.id
        });
        this.$router.push({
          path: "/course/Classes",
          query: {
            id: this.data.id
          }
        });
      }
    },
    // 视频切换
    handleVideo(type){
      this.data.videoSrc = this.data[type]
    },
    // 我要考试
    handleExam() {
      let loginFlag = localStorage.getItem("pcToken") || "";
      if (!loginFlag) {
        this.$message.error("请登录");
        setTimeout(() => {
          this.$router.replace("/login");
        }, 1500);
        return;
      }
      if (this.data.testLink.includes("http")) {
      } else {
        this.tipValue = "我要考试";
        this.dialogVisible = true;
      }
    }
  }
};
</script>
<style lang="scss">
.bannerContent {
  height: 498px;
  padding: 30px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(0, 60, 164, 0.2);
  border-radius: 8px;
  margin-top: 48px;
  .left {
    width: 660px;
    .title {
      margin-bottom: 30px;
      .title1 {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333e55;
      }
      .title2 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        .label {
          color: #333333;
        }
        .value {
          color: #3989eb;
        }
      }
    }
    .videoBox{
      video {
      width: 100%;
      height: 335px;
    }
    img {
      width: 100%;
      height: 370px;
    }
    .button{
      margin-top: 10px;
    }
    }
    
  }
  .right {
    width: 400px;
    position: relative;
    .title {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333e55;
      margin-bottom: 30px;
    }
    .content {
      .describe {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        margin-bottom: 17px;
      }
      .text {
        height: 220px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .mark {
        margin-top: 15px;
        p {
          img {
            width: 15px;
            height: 17px;
            margin-right: 7px;
            cursor: pointer;
          }
          span {
            font-size: 16px;
            font-family: Tensentype RuiHeiJ-W4;
            font-weight: 500;
            color: #333e55;
            margin-right: 40px;
          }
        }
      }
      .button {
        position: absolute;
        bottom: 0px;
        button {
          width: 180px;
          height: 46px;
          border-radius: 4px;
              font-size: 16px;
    font-weight: bold;
        }
        .btn1 {
          background: linear-gradient(135deg, #28b9f5, #3989eb);
        }
        .btn2 {
          background: linear-gradient(-45deg, #319538, #83bf54);
          border: none;
        }
      }
    }
  }
}
</style>
