import Vue from "vue";
import Vuex from "vuex";
import { BUSINESSPROGRESS, BASEURL } from "@/api/ApiConfig";
// import request from '@/api/request.js'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    UserMeterList: [],
    showDia: false,
    payType: "mis",
    billNo: ""
  },
  mutations: {
    setUserMeterList: (state, UserMeterList) => {
      state.UserMeterList = UserMeterList;
      sessionStorage.setItem("UserMeterList", JSON.stringify(UserMeterList));
    },

    setWaterType: (state, WaterType) => {
      state.WaterType = WaterType;
    },
    setShow: (state, res) => {
      state.showDia = res;
    }
  },
  actions: {
    // 获取绑定的户号
    getUserMeterList({ commit }) {
      return new Promise((resolv, reject) => {
        // request({
        //   url: BUSINESSPROGRESS,
        //   baseURL: BASEURL,
        //   method: "post",
        //   params: {},
        // }).then(res => {
        //   if (res.status == 0) {
        //     commit('setUserMeterList', res.data)
        //   } else {
        //   }
        //   resolv(res)
        // });
      });
    }
  },
  modules: {}
});
