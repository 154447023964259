<!-- 绑定提示 -->
<template>
  <div class="footer">
    <div class="up">
      <img src="@/assets/images/home/footer_icon.png" alt="" />
      <div class="text">
        <p>服务热线: 15382305057</p>
      </div>
    </div>
    <div class="down">Copyright © 杭州辰有智能科技有限公司 版权所有 浙ICP备2023033855号-2</div>
  </div>
</template>

<script>
export default {
  //声明组件
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  activated() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 110px;
  background: #0A1732;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  z-index: 9999;
  bottom: 0;
  .up {
    display: flex;
    align-items: center;
    color: #fff;
    img {
      width: 14px;
      height: 13px;
    }
    .text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      p {
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
      }
    }
  }
  .down {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
  }
  .box_1200 {
    width: 1200px;
    height: 100%;
  }
}
</style>
