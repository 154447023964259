<template>
  <ul class="menu2-wrapper">
    <li
      class="main_tab"
      v-for="item in ruleCodes"
      :key="item.value"
      :label="item.value"
      @click="handleClick(item)"
      :class="number == item.value ? 'blue' : ''"
    >
      <img :src="item.unCheckIcon" alt="" v-if="number == item.value" />
      <img :src="item.CheckIcon" alt="" v-else />
      <span>{{ item.label }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      ruleCodes: [
        {
          label: "我的收藏",
          value: "1",
          CheckIcon: require("@/assets/images/icon/sc-blue.png"),
          unCheckIcon: require("@/assets/images/icon/sc-white.png")
        },
        {
          label: "浏览记录",
          value: "2",
          CheckIcon: require("@/assets/images/icon/lljl-blue.png"),
          unCheckIcon: require("@/assets/images/icon/lljl-white.png")
        },
        {
          label: "参与实验",
          value: "3",
          CheckIcon: require("@/assets/images/icon/cysy-blue.png"),
          unCheckIcon: require("@/assets/images/icon/cysy-white.png")
        },
        {
          label: "学习课程",
          value: "4",
          CheckIcon: require("@/assets/images/icon/xxkc-blue.png"),
          unCheckIcon: require("@/assets/images/icon/xxkc-white.png")
        },
        {
          label: "我的资料",
          value: "5",
          CheckIcon: require("@/assets/images/icon/wdzl-blue.png"),
          unCheckIcon: require("@/assets/images/icon/wdzl-white.png")
        },
        {
          label: "账户设置",
          value: "6",
          CheckIcon: require("@/assets/images/icon/zhsz-blue.png"),
          unCheckIcon: require("@/assets/images/icon/zhsz-white.png")
        }
      ],
      number: "1",
      personIcon: require("@/assets/images/login/person.png"),
      routeName: ""
    };
  },
  watch: {
    routeName: {
      handler(newValue, oldValue) {
        if (newValue == "我的收藏") {
          this.number = 1;
        } else if (newValue == "浏览记录") {
          this.number = 2;
        } else if (newValue == "参与实验") {
          this.number = 3;
        } else if (newValue == "学习课程") {
          this.number = 4;
        } else if (newValue == "我的资料") {
          this.number = 5;
        } else if (newValue == "账户设置") {
          this.number = 6;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.routeName = localStorage.getItem("routeName");
  },
  mounted() {},
  methods: {
    // tab点击事件
    handleClick(item) {
      this.number = item.value;
      if (this.number == 1) {
        var path = "/personalCenter";
      } else if (this.number == 2) {
        var path = "/personalCenter/BrowsingHistory";
      } else if (this.number == 3) {
        var path = "/personalCenter/participateExperiments";
      } else if (this.number == 4) {
        var path = "/personalCenter/learningCourses";
      } else if (this.number == 5) {
        var path = "/personalCenter/myProfile";
      } else if (this.number == 6) {
        var path = "/personalCenter/accountSettings";
      }
      this.$router.push({
        path
      });
    }
  }
};
</script>
