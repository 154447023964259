<template>
  <div class="resource">
    <div class="classify">
      <div v-for="(item, index) in types" :key="index" class="wrap flex jc-between ai-center">
        <div class="label">{{ item.name }}</div>
        <div class="list flex-1 flex ai-center">
          <div v-for="(child, idx) in item.list" :key="idx" :class="['item cursor-pointer', { 'actived': item.actived === idx }]" @click="handleTypeClick(item, idx)">{{ child.name }}</div>
        </div>
      </div>
    </div>
    <div class="table flex flex-wrap">
      <div v-for="(item, index) in list" :key="index" class="item flex flex-column relative cursor-pointer">
        <img :src="item.type" alt="" class="img absolute">
        <div class="txt absolute">{{ item.name }}</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        align="center"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 40, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <img src="../../assets/pcResource.png" alt=""> -->
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      types: [
        {
          id: 0,
          name: '专业大类：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '计算机'
            },
            {
              id: 2,
              name: '医学'
            },
            {
              id: 3,
              name: '音乐'
            },
            {
              id: 4,
              name: '建筑'
            }
          ]
        },
        {
          id: 2,
          name: '课程类型：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '编程'
            },
            {
              id: 2,
              name: '3D建模'
            },
            {
              id: 3,
              name: '设计'
            },
            {
              id: 4,
              name: '网络'
            }
          ]
        },
        {
          id: 3,
          name: '课程系列：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '建筑领域'
            },
            {
              id: 2,
              name: '服务领域'
            },
            {
              id: 3,
              name: '计算机领域'
            },
            {
              id: 4,
              name: '金融领域'
            }
          ]
        },
        {
          id: 4,
          name: '文件类型：',
          actived: 0,
          list: [
            {
              id: 0,
              name: '全部'
            },
            {
              id: 1,
              name: '文本文档'
            },
            {
              id: 2,
              name: '视频资料'
            },
            {
              id: 3,
              name: '全景图资料'
            },
            {
              id: 4,
              name: '模型资料'
            }
          ]
        }
      ],
      page: 1,
      size: 10,
      total: 10,
      list: [
        {
          type: require('../../assets/images/resource/img-icon.png'),
          name: 'wechatimg577.jpg'
        },
        {
          type: require('../../assets/images/resource/img-icon.png'),
          name: 'wechatimg578.jpg'
        },
        {
          type: require('../../assets/images/resource/pdf-icon.png'),
          name: 'wechatimg579.jpg'
        },
        {
          type: require('../../assets/images/resource/pdf-icon.png'),
          name: 'wechatimg590.jpg'
        },
        {
          type: require('../../assets/images/resource/word-icon.png'),
          name: 'wechatimg597.jpg'
        },
        {
          type: require('../../assets/images/resource/video-icon.png'),
          name: 'wechatimg567.jpg'
        },
        {
          type: require('../../assets/images/resource/video-icon.png'),
          name: 'wechatimg566.jpg'
        },
        {
          type: require('../../assets/images/resource/video-icon.png'),
          name: 'wechatimg565.jpg'
        },
        {
          type: require('../../assets/images/resource/video-icon.png'),
          name: 'wechatimg564.jpg'
        },
        {
          type: require('../../assets/images/resource/video-icon.png'),
          name: 'wechatimg563.jpg'
        },
      ]
    };
  },
  created() {
    this.getData()
  },
  activated() {
  },
  methods: {
    handleTypeClick(item, idx) {
      item.actived = idx
    },
    getData() {
      // queryStudyDetail({
      //   page: this.data.page,
      //   size: this.data.size
      // }).then(res => {
      //   if (res.code === 200) {
      //     this.list = res.data.rows || [];
      //     this.total = res.data.total
      //   }
      // });
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  }
};
</script>
<style lang="scss">
.resource {
  padding-top: 109px;
  .classify {
    width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(45,77,130,0.11);
    border-radius: 12px;
    padding: 20px 30px;
    margin: 0 auto;
    .wrap {
      padding: 16px 0;
      .label {
        width: 90px;
        height: 25px;
        font-weight: bold;
        font-size: 18px;
        color: #327CED;
        line-height: 25px;
        text-align: left;
        font-style: normal;
      }
      .item {
        font-size: 16px;
        color: #697B95;
        line-height: 38px;
        padding: 0 16px;
        &.actived {
          background: #327CED;
          border-radius: 6px;
          color: #FFFFFF;
        }
      }
    }
  }
  .table {
    width: 1400px;
    margin: 20px auto;
    .item {
      width: 280px;
      height: 264px;
      .img {
        left: 0;
        top: 0;
        width: 280px;
        height: 264px;
      }
      .txt {
        left: 0;
        bottom: 20px;
        z-index: 2;
        width: 280px;
        font-weight: bold;
        font-size: 18px;
        color: #234984;
        line-height: 25px;
        text-align: center;
      }
    }
  }
  .pagination {
    margin-top: 20px;
  }
}
</style>
