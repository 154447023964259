<template>
  <div class="News flex box_1200">
    <PersonMenu />
    <div class="input-wrapper flex jus-b align-c">
      <div class="input flex align-c">
        <el-input v-model="inputValue" placeholder="请输入搜索关键词" clearable></el-input>
        <div class="search-wrapper" @click="getData">
          <img :src="searchIcon" alt="" />
        </div>
      </div>
    </div>
    <PortraitMore
      :isShowCollect="false"
      :listData="listData"
      :pageTotal="pageTotal"
      @getPageSize="getPageSize"
      @getPageIndex="getPageIndex"
    />
  </div>
</template>
<script>
import { NoticeModuleList } from "@/api/ApiConfig";
import PersonMenu from "@/views/News/components/menu";
import PortraitMore from "@/components/PortraitMore";
export default {
  components: {
    PersonMenu,
    PortraitMore
  },
  data() {
    return {
      searchIcon: require("@/assets/images/icon/newsSearch.png"),
      inputValue: "",
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      listData: []
    };
  },
  created() {
    let query = this.$route.query;
    if (query && query.inputValue) {
      this.inputValue = query.inputValue;
    }
    this.getData();
  },
  methods: {
    getPageIndex(val) {
      this.pageIndex = val;
      this.getData();
    },
    getPageSize(val) {
      this.pageSize = val;
      this.getData();
    },
    getData() {
      this.openLoading();
      let data = {
        channel: "新闻",
        pageIndex: this.pageIndex || 1,
        pageSize: this.pageSize || 10,
        readState: "0",
        title: this.inputValue
      };
      NoticeModuleList(data).then(res => {
        this.openLoading().close();
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.listData = res.data.list;
        this.pageTotal = res.data.total;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.News {
  padding-top: 109px;
  position: relative;
}
.input-wrapper {
  position: absolute;
  right: 40px;
  top: 149px;
  border: none;
}
.input {
  border: 1px solid #e5e5e5;
  border-radius: 19px;
  /deep/.el-input {
    input {
      border-radius: 19px;
      height: 32px;
      border: none;
      outline: none;
    }
  }
  .search-wrapper {
    height: 38px;
    background: linear-gradient(135deg, #28b9f5, #3989eb);
    border-radius: 0 19px 19px 0;
    line-height: 34px;
    padding: 0 19px 0 13px;
    cursor: pointer;
    img {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
  }
}
</style>
