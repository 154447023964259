<template>
  <div class="team-pager">
    <div class="banner">
      <img class="img" src="../images/pager2-banner.png" />
    </div>
    <div class="detail">
      <div class="title relative">教学实验团队</div>
      <div class="inner flex flex-wrap">
        <div v-for="(item, index) in teams" :key="index" class="team-item cursor-pointer flex flex-column jc-center ai-center" @click="handleTeamDeatil(item)">
          <div class="photo">
            <img class="img" :src="item.photo" />
          </div>
          <div class="flex flex-column ai-normal">
            <div class="name text-align-center">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryStudyDetail } from "@/api/ApiConfig.js";

export default {
  components: {},
  props: ['pager', 'params'],
  data() {
    return {
      laboratory_id: localStorage.getItem('laboratory_id') || null,
      teams: []
    };
  },
  computed: {
  },
  mounted() {
    this.queryStudyDetail()
  },
  methods: {
    changePager({ pager, params }) {
      this.$emit('change', { pager, params })
    },
    async queryStudyDetail() {
      const res = await queryStudyDetail({
        id: this.laboratory_id
      })
      if (res.code === 200) {
        const teams = [JSON.parse(res.data.teamHead), ...JSON.parse(res.data.teamUser)]
        this.teams = teams
      }
    },
    handleTeamDeatil(item) {
      this.$alert(item.desc, item.name)
    }
  }
};
</script>
<style lang="scss" scoped>
.team-pager {
  background-color: #F4F5F8;
  .banner {
    margin: 0 auto 25px;
    .img {
      width: 100%;
      height: auto;
    }
  }
  .detail {
    width: 100%;
    max-width: 1218px;
    margin: 25px auto 50px;
    box-sizing: border-box;
    .title {
      margin: 50px 0 6px;
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      line-height: 37px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -10px;
        width: 22px;
        height: 4px;
        background: #327CED;
        border-radius: 2px;
      }
    }
    .inner {
      margin-top: 30px;
      width: 1240px;
      .team-item {
        width: 276px;
        height: 364px;
        background: #FFFFFF;
        box-shadow: 0px 0px 27px 0px rgba(113,143,246,0.25);
        border-radius: 138px;
        margin: 16px;
        .photo {
          width: 124px;
          height: 124px;
          overflow: hidden;
          margin: 0 auto 20px;
          .img {
            width: 124px;
            height: 124px;
            border-radius: 62px;
          }
        }
        .name {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 26px;
          margin-bottom: 10px;
        }
        .desc {
          width: 180px;
          height: 100px;
          font-size: 14px;
          color: #54637B;
          line-height: 20px;
          margin: 0 auto;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
